import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  InputAdornment,
  CircularProgress, Backdrop
} from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import Header from "../../navigationmenu/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
import {
  backgroundImg,
  starIcon,
  uploadPhoto,

} from "./assets";
import "./EmailAccountRegistration.css";
import Cropper from 'react-easy-crop';
import * as Yup from "yup";
import { Formik, FormikErrors, FormikTouched } from "formik";
import { Touched, Error } from "./EmailAccountRegistrationController";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { differenceInYears, isValid} from 'date-fns';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    "fontFamily": `"Museo Sans", sans-serif`,
   }
});

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start

  userSchema = () => {
    return Yup.object().shape({
      first_name: Yup.string().required('Name is required'),
      user_name: Yup.string().required('User Name is required'),
      date_of_birth: Yup.date()
        .nullable()
        .test("isValidDate", "Please enter a valid date", function (value) {
          return isValid(new Date(value));
        })
        .test("dob", "Age should be greater than 18", function (value) {
            return differenceInYears(new Date(), new Date(value)) >= 18;
        })
        .required("Date of Birth is required"),
      country_code: Yup.string()
        .min(5, 'Zip Code must be of 5 numbers')
        .max(5, 'Zip Code must be of 5 numbers')
        .matches(/^\d{5}$/, 'Zip Code must contain only 5 numbers')
        .required('Zip Code is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password_digest: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .test(
          'password-requirements',
          'Password must contain at least two of the following: lowercase letter, uppercase letter, number, or special character (@#$^&*+=)',
          (value) => {
            const lower = /[a-z]/.test(value);
            const upper = /[A-Z]/.test(value);
            const number = /\d/.test(value);
            const special = /[!@#$^&*+=]/.test(value);
            const count = [lower, upper, number, special].filter(Boolean).length;
            return count >= 2;
          }
        )
        .required('Password is required!'),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password_digest'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "-8px", fontSize: "12px", color: "#f94b4b",textAlign:"right" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { imageUrl, croppedImage, crop, zoom } = this.state;
    const currentDate = new Date().toISOString().split('T')[0]; 

    const imageSrc = croppedImage || imageUrl || uploadPhoto;
    const altText = croppedImage ? "Cropped" : imageUrl ? "Uploaded" : "Upload";

    const imageElement = (
      <img
        src={imageSrc}
        alt={altText}
        style={{ width: '100px', height: '100px', borderRadius: "50%", objectFit: "cover", overflow: "hidden" }}
      />
    );

    const getInputType = (isEnabled:boolean) => (isEnabled ? "text" : "password");

    return (
      <ThemeProvider theme={theme}>
      <Box style={{ overflowX: "hidden" }}>
        <Header {...this.props} />
        <Box style={webStyle.mainContainer}>
          <Box className="live-area">
            <Box style={webStyle.formContainer} className="form-container">
              <Box
                className="acount-upload-img-wrapper"
              >
                <div>
                  <button style={{ border: "none", backgroundColor: "transparent" }}>
                    <input
                      type="file"
                      id="profilePicture"
                      style={{ display: 'none' }}
                      onChange={this.onHandleFileChange}
                    />
                    <label htmlFor="profilePicture">
                      <div style={{ border: 'none', padding: '10px', cursor: 'pointer' }}>
                        {imageElement}
                      </div>
                    </label>
                    <Typography className="upload-profile-patron">Upload Profile Picture</Typography>
                  </button>
                  {imageUrl && !croppedImage && (
                    <div>
                      <div style={{ position: 'relative', width: '100%', height: 400 , marginTop:"8px"}}>
                        <Cropper
                          image={imageUrl}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          cropShape="round"
                          objectFit="vertical-cover"
                          onCropChange={this.onCropChange}
                          onZoomChange={this.onZoomChange}
                          onCropComplete={this.onCropComplete}
                        />
                      </div>
                      <button className="upload-btn-account"  onClick={this.showCroppedImage}>Upload</button>
                    </div>
                  )}
                </div>
              </Box>
              <Formik
                data-test-id="formik2"
                initialValues={{
                  first_name: this.state.first_name,
                  user_name:this.state.user_name,
                  date_of_birth: this.state.date_of_birth,
                  country_code: this.state.country_code,
                  email: this.state.email,
                  password_digest: this.state.password_digest,
                  confirm_password: this.state.confirm_password,
                }}
                enableReinitialize={true}
                onSubmit={(values) => { this.handleClick(); }}
                validationSchema={this.userSchema}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
              <Grid container className="grid-container-registration">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="muiGrid-item-1"
                >
                    <Box style={webStyle.txtFieldStyle}>
                      <img className="star-icon-signup" src={starIcon} />
                      <Box className="textfield-error-wrapper">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        placeholder="Name"
                        inputProps={{maxLength:20}}
                        className="formInputStyle"
                        value={this.state.first_name}
                        style={webStyle.textStyle}
                        data-test-id={"inputName"}
                        fullWidth
                        name="first_name"
                        onChange={(event) => {
                          this.setSignUpFields("first_name", event.target.value);
                          setFieldValue("first_name", event.target.value);
                        }}
                      />
                        {this.getErrorMessage(touched, errors, "first_name")}
                        <div className="invalid-error-backend">{this.state.invalidFirstName}</div>
                      </Box>
                    </Box>
                    <Box style={webStyle.txtFieldStyle}>
                      <img className="star-icon-signup" src={starIcon} />
                      <Box className="textfield-error-wrapper">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        className="formInputStyle"
                        data-test-id={"inputNameUser"}
                        style={webStyle.textStyle}
                        value={this.state.user_name}
                        placeholder="User Name"
                        inputProps={{maxLength:20}}
                        fullWidth
                        name="user_name"
                        onChange={(event) => {
                          this.setSignUpFields("user_name", event.target.value);
                          setFieldValue("user_name", event.target.value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "user_name")}
                      <div className="invalid-error-backend">{this.state.invalidUserName}</div>
                      </Box>
                    </Box>
                    <Box style={webStyle.txtFieldStyle}>
                      <img className="star-icon-signup" src={starIcon} />
                      <Box className="textfield-error-wrapper">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        className="formInputStyle"
                        data-test-id={"inputDOB"}
                        style={webStyle.textStyle}
                        value={this.state.date_of_birth}
                        placeholder={!this.state.date_of_birth ? "Date of Birth" : ""}
                        type={this.state.date_of_birth ? "date" : "text"}                       
                        fullWidth
                        inputProps={{ max: currentDate }}
                        name="date_of_birth"
                        onChange={(event) => {
                          this.setSignUpFields("date_of_birth", event.target.value);
                          setFieldValue("date_of_birth", event.target.value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "date_of_birth")}
                      </Box>
                    </Box>
                    <Box style={webStyle.txtFieldStyle}>
                      <img className="star-icon-signup" src={starIcon} />
                      <Box className="textfield-error-wrapper">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        placeholder="Zip Code"
                        size="small"
                        inputProps={{maxLength: 5}}
                        className="formInputStyle"
                        style={webStyle.textStyle}
                        value={this.state.country_code}
                        data-test-id={"zipCode"}
                        fullWidth
                        name="country_code"
                        onChange={(event) => {
                          this.setSignUpFields("country_code", event.target.value);
                          setFieldValue("country_code", event.target.value);
                        }}
                      />
                        {this.getErrorMessage(touched, errors, "country_code")}
                        {this.state.invalidError && <div className="invalid-error-backend">Invalid Zip Code</div>}
                        </Box>
                    </Box>
                    <Box style={webStyle.txtFieldStyle}>
                      <img className="star-icon-signup" src={starIcon} />
                      <Box className="textfield-error-wrapper">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        placeholder="Email"
                        className="formInputStyle"
                        data-test-id={"inputEmail"}
                        style={webStyle.textStyle}
                        value={this.state.email}
                        size="small"
                        fullWidth
                        name="email"
                        onChange={(event) => {
                          this.setSignUpFields("email", event.target.value);
                          setFieldValue("email", event.target.value);
                        }}
                      />
                        {this.getErrorMessage(touched, errors, "email")}
                        {this.state.invalidEmail && <div className="invalid-error-backend">Email has already been taken</div>}
                        </Box>
                    </Box>
                    <Box style={webStyle.txtFieldStyle}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        placeholder="Your Favorite Watering Hole"
                        size="small"
                        className="formInputStyle"
                        data-test-id={"favWateringHole"}
                        value={this.state.favorite_watering_hole}
                        style={{...webStyle.textStyle, marginLeft:"20px"}}
                        fullWidth
                        name="favorite_watering_hole"
                        onChange={(e) =>
                          this.setSignUpFields(e.target.name, e.target.value)}
                      />
                    </Box>
                    <Box style={webStyle.txtFieldStyle}>
                    <img className="star-icon-signup" src={starIcon} />
                    <Box className="textfield-error-wrapper">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        placeholder="Password"
                        size="small"
                        className="textStyle"
                        type={getInputType(this.state.enablePassword)}
                        data-test-id={"inputPassword"}
                        style={webStyle.textStyle}
                        fullWidth
                        value={this.state.password_digest}
                        name="password_digest"
                        onChange={(event) => {
                          const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                          this.setSignUpFields("password_digest", valueWithoutSpaces);
                          setFieldValue("password_digest", valueWithoutSpaces);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.enablePassword ?  <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                        {this.getErrorMessage(touched, errors, "password_digest")}
                        </Box>
                    </Box>
                    <Box>
                      <Typography variant="body1" className="passValidationSignup">
                        Password requirements: minimum of 8 characters <b>USE AT LEAST 2</b> of the following classes:
                        <ul className="pass-list">
                          <li>lower case letters, upper case letter(s), number(s)</li>
                          <li>Special Character(s) (@#$^&*+=)</li>
                        </ul>
                      </Typography>
                    </Box>
                </Grid>
                <Grid
                  item
                  className="muiGrid-item-2"
                  xs={12}
                  sm={6}
                >
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography style={webStyle.grid2Heading}>
                      Select What is Important to You
                    </Typography>
                  </Box>
                  <Grid container className="icongridStyle" >
                    <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: "1.99vw" }}>
                      {this.state.important_feature.map((feature) => (
                        <Box key={feature.name} className={this.state.select_important.includes(feature.name) ? "icon-container-business" : "icon-container-business opacity-inactive" } onClick={ () => this.handleIconClickRegistration(feature.name)} >
                          <img
                            src={feature.icon.url}
                            alt={feature.name}
                            className="icon-img-customisable"
                            style={{ display: 'block', margin: '0 auto' }}
                          />
                          <span className="fitericon-span">{feature.name}</span>
                        </Box>
                      ))}
                    </div>
                  </Grid>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    placeholder="Other"
                    size="small"
                    className="formInputStyle"
                    data-test-id={"txtInputOther"}
                    style={webStyle.textStyle}
                    value={this.state.others}
                    fullWidth
                    name="others"
                    onChange={(e) =>
                      this.setSignUpFields(e.target.name, e.target.value)}
                  />
                  <Box style={{ ...webStyle.txtFieldStyle, marginLeft: "-20px"}}>
                    <img className="star-icon-signup" src={starIcon} />
                      <Box className="textfield-error-wrapper">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        placeholder="Re enter Password"
                        type={getInputType(this.state.enableReTypePassword)}
                        size="small"
                        className="textStyle"
                        data-test-id={"confirmPassword"}
                        value={this.state.confirm_password}
                        style={{...webStyle.textStyle}}
                        fullWidth
                        name="confirm_password"
                        onChange={(event) => {
                          const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                          this.setSignUpFields("confirm_password", valueWithoutSpaces);
                          setFieldValue("confirm_password", valueWithoutSpaces);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowConfirmPassword}
                                onMouseDown={this.handleMouseDownConfirmPassword}
                              >
                                {this.state.enableReTypePassword ?  <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                        {this.getErrorMessage(touched, errors, "confirm_password")}
                        </Box>
                    </Box>
                </Grid>
              </Grid>
              <Box>
                <Backdrop
                  open={this.state.loading}
                  style={{ color: '#fff', zIndex: 1000 }}
                >
                  <CircularProgress color="inherit" size={30} />
                </Backdrop>
              </Box>
              <Box style={{ ...webStyle.center, ...webStyle.agree }}>
                <Checkbox
                  onChange={(e) =>
                  this.setSignUpFields("agree_terms", e.target.checked)}
                  checked={this.state.agree_terms}
                  data-test-id={"agreeTermsCheckbox"} />
                <Typography variant="body2" className="agree-to-typo">
                  Agree to {" "}
                  </Typography>
                  <Button style={webStyle.secondaryColor} data-test-id="agreeBtn" className="terms-conds-btn" onClick={()=> this.goToTermsAndConditionRegistration()}>Terms and Conditions</Button>
              </Box>
              <Box style={{ ...webStyle.center, ...webStyle.btn }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  data-test-id={"SignupBtn"}
                  style={webStyle.btnStyle}
                  disabled={!this.state.agree_terms}
                >
                        Submit
                      </Button>
                      <Snackbar open={this.state.successSnackbar} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <Alert
                          severity="success"
                          variant="filled"
                          className="alert-submission"
                          sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                        >
                          Account created successfully!
                        </Alert>
                      </Snackbar>
                    </Box>
              </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
        <Footer {...this.props} />
      </Box>
      </ThemeProvider>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {
    backgroundColor: "#fff",
    paddingTop: "84px",
    paddingBottom: "44px",
    marginTop: "158px",
    marginBottom: "87px",
    width:"84.45%",
    borderRadius:"8px"
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    gap: "7.14px",
    height: "55px",
  },
  iconGrid: {
    marginBottom: "35.18px",
    justifyContent: "space-between",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnStyle: {
    backgroundColor: "70211F",
    width: "200px",
    height:"56px",
    fontSize:"18px",
    fontWeight:600,
    borderRadius:8,
    color:"#fff",
  },
  agree: {
    marginTop: "31px",
  },
  btn: {
    marginTop: "32px",
  },
  secondaryColor: {
    color: "#9C0E2E",
    fontWeight:600,
    marginTop:"-1px",
    marginLeft:"-4px"
  },
  txtFieldStyle: {
    display: "flex",
    alignItems: "center",
    gap: "7px",
    fontFamily: 'Museo Sans", sans-serif',
  },
  iconTextStyle: {
    fontSize: "7.13px",
    color: "712220",
    marginTop: "7.14px",
  },
  grid2Heading: {
    color: "#732120",
    fontWeight: 700,
    marginBottom: "32px",
    alignItem: "center",
    justifyItem: "center",
    fontFamily: 'Museo Sans", sans-serif',
    fontSize: "1.11vw",
  },
  textStyle: {
    fontFamily: 'Museo Sans", sans-serif',
  }
};
// Customizable Area End