export const imgReport = require("../assets/image_report.svg");
export const imgShare = require("../assets/image_share.svg");
export const imgStar = require("../assets/image_star.svg");
export const likeStar = require("../assets/like.svg");
export const badge1 = require("../assets/badge1.svg");
export const badge2 = require("../assets/badge2.svg");
export const badge3 = require("../assets/badge3.svg");
export const badge4 = require("../assets/badge4.svg");
export const badge5 = require("../assets/badge5.svg");
export const activityAvatar = require("../assets/activity_avatar.svg");
export const arrowLeft = require("../assets/arrow_left.svg");
export const arrowRight = require("../assets/arrow_right.svg");
export const btnCancel = require("../assets/image_close.svg");
export const menuSs = require("../assets/menu_ss.svg");
export const dartIcon = require("../assets/group_Darts_Icon.svg");
export const liveMusicIcon = require("../assets/group_Live_Music_Icon.svg");
export const karaokeIcon = require("../assets/group_Karaoke_Icon.svg");
export const poolIcon = require("../assets/group_Pool_Icon.svg");
export const triviaIcon = require("../assets/group_Trivia_Icon.svg");
export const patioIcon = require("../assets/group_Patio_Icon.svg");
export const partyRoomIcon = require("../assets/group_Group_355.svg");
export const foodIcon = require("../assets/group_Food_Icon.svg");
export const gamblingIcon = require("../assets/group_Gambling_Icon.svg");
export const happyHourIcon = require("../assets/group_Happy_Hour_Icon.svg");
export const petIcon = require("../assets/group_Pet_Icon.svg");
export const nonAlcIcon = require("../assets/group_Group_357.svg");
export const arcadeIcon = require("../assets/group_Arcade.svg");
export const leagueIcon = require("../assets/group_League_Pass.svg");
export const gtIcon = require("../assets/group_GT.svg");
export const CloseBtn =  require("../assets/button_close_white.svg");
export const leftArrow =  require("../assets/button_icon_left.svg");
export const RightArrow =  require("../assets/button_icon_right.svg");