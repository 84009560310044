import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { barIcon, wineryIcon, restaurantIcon, breweryIcon } from "./assets";
interface Bar {
  id: string;
  business_name: string;
  latitude:number;
  longitude:number;
  distance:string;
  help_us_help_you:{
    catgory_name:string | null;
  };
  like:boolean;
  images: Images[];
  total_check_ins: number;
}

interface SelectedBar {
  id: string;
  business_name: string;
  latitude:number;
  longitude:number;
  distance:string;
  help_us_help_you:{
    catgory_name:string;
  };
  like:boolean;
  images: Images[];
  total_check_ins: number;
}
interface LikedBusinesses {
  [key: string]: boolean;
}

interface ActiveImageIndex{ 
  [key: string]: number;
} 

interface FeatureIcon {
  url: string;
  type: string;
  filename: string;
};

interface SubCategory{
  name:string;
}
interface Images {
  id:number;
  url: string;
  type: string;
  filename: string;
};

interface ImportantFeature{
  id: string;
  name: string;
  active:boolean;
  icon: FeatureIcon;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  value:number;
  tabValue:string;
  activeTab:number;
  bars: Bar[];
  search:string;
  changeLocation:string;
  isLocation:boolean;
  userLatitude:number;
  userLongitude:number;
  setOpen:boolean;
  selectedBar: SelectedBar| null ;
  important_feature:string[];
  subCategory:string;
  loginToken:string;
  loginSnackbar:boolean;
  businessLiked:boolean;
  likedBusinesses:{
    [key: string]: boolean;
  };
  filterIcons: ImportantFeature[];
  important_categories: string[];
  categories:string[];
  barType:SubCategory[];
  activeImageIndex: { [key: number]: number }; 
  isLoading:boolean;
  noDataError:string;
  mapCenter: {
    lat: number,
    lng: number,
  },
  likedAcc:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  likeBusinessCallId: string = "";
  fiterItemsApiCallId: string = "";
  profileViewedCallId: string = "";
  getCatSubcatApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      value:0,
      tabValue:"all",
      activeTab:0,
      bars:[],
      search:"",
      changeLocation:"",
      isLocation:true,
      userLatitude:37.09024,
      userLongitude:-95.712891,
      setOpen:false,
      selectedBar:null,
      important_feature:[],
      subCategory:"",
      loginToken:"",
      loginSnackbar:false,
      businessLiked:false,
      likedBusinesses:{},
      filterIcons:[],
      important_categories: [],
      categories:[],
      barType:[],
      activeImageIndex: {},
      isLoading:false,
      noDataError:"",
      mapCenter: {
        lat: 37.09024,
        lng: -95.712891,
      },
      likedAcc:false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const currentPath = window.location.pathname;
    if (/^\/Explore\/\d+$/.test(currentPath)) {
      this.setState( { likedAcc: true }, () => { this.getAllList(); } );
    }else{
      this.getAllList()
    }    
    this.getFilterItems()
    this.getCategorySubcategoryData()
    this.getLocation()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const sessionResponseId = getName(MessageEnum.SessionResponseMessage);
    const restApiResponseId = getName(MessageEnum.RestAPIResponceMessage);

    const token = window.localStorage.getItem("token")
    if (token){
      this.setState({loginToken: token})
    }
  
    if (message.id === sessionResponseId) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token:token});
    } else if (message.id === restApiResponseId &&
      this.advancedsearchApiCallId !== "" &&
      this.advancedsearchApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
        const likedBusinesses: LikedBusinesses = {};
        const initialActiveIndices: ActiveImageIndex = {};
        if(responseJson && !responseJson.errors){
          this.setState(
            { bars: responseJson },
            () =>
              this.state.bars.length === 1 &&
              this.setState({
                mapCenter: { lat: responseJson[0].latitude, lng: responseJson[0].longitude },
              })
          );
          this.setState({isLoading:false, noDataError:""})
          responseJson.forEach((bar: Bar )=> { likedBusinesses[bar.id] = bar.like; initialActiveIndices[bar.id] = 0;});
          this.setState({ likedBusinesses });
          this.setState({ activeImageIndex: initialActiveIndices });
          if (responseJson.accounts) {
            if (typeof responseJson.accounts === "string") {
            } else {
              this.setState({ advancedsearchList: responseJson.accounts.data });
            }
          } 
        }else{
            this.setState({bars:[]})
            this.setState({isLoading:false})
            this.setState({noDataError:responseJson.errors[0].message})
        }
        runEngine.debugLog("API Message Received", message);
    }else if (message.id === restApiResponseId &&
      this.fiterItemsApiCallId !== "" &&
      this.fiterItemsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if(responseJson && !responseJson.errors){
          this.setState({filterIcons:responseJson.filter_icons})
          this.setState({categories:responseJson.categories})
        }else{
            alert(responseJson.errors[0].message);
        }
        runEngine.debugLog("API Message Received", message);
    } else if (message.id === restApiResponseId &&
      this.getCatSubcatApiCallId !== "" &&
      this.getCatSubcatApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setState({ barType: responseJson.data })
    } 
     // Customizable Area End
  }

  // Customizable Area Start

  handleCircleClick = (barId: number, index: number) => {
    this.setState((prevState) => ({
      activeImageIndex: {
        ...prevState.activeImageIndex,
        [barId]: index,
      },
    }));
  };
   

  handleLikeChange = (businessId:string) => {
    this.setState(
      (prevState) => ({
        likedBusinesses: {
          ...prevState.likedBusinesses,
          [businessId]: !prevState.likedBusinesses[businessId],
        },
      }),
      () => {
        this.likedBusinessAccount(businessId);
      }
    );
  };
  

  likedBusinessAccount = (businessId: string) => {

    let Token = window.localStorage.getItem("token");

    const header = {
      token: Token
    };

    const formData = new FormData();

    formData.append("likeable_id", businessId);
    formData.append("active_like", this.state.likedBusinesses[businessId].toString());

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.likeBusinessCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.businessLikeEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  pleaseLoginPopup = () => {
    this.setState({ loginSnackbar: true }, () => {
      setTimeout(() => {
        this.setState({ loginSnackbar: false });
      }, 2000);
    });
  };

  handleIconClick = (text: string) => {
    
    let updatedFeatures = [...this.state.important_feature];

    if (updatedFeatures.includes(text)) {
      updatedFeatures = updatedFeatures.filter((feature) => feature !== text);
    } else {
      updatedFeatures.push(text);
    }
    this.setState((prevState) => ({
      ...prevState,
      important_feature: updatedFeatures
    }), () => {
      this.getAdvancedSearchList()
    });
  }

  getLocation = async (event?: any) => {
    const isLocationEnabled = event ? event.target.checked : this.state.isLocation;
  
    if (!isLocationEnabled) {
      this.setState({ isLocation: false }, this.getAdvancedSearchList);
      return;
    }
  
    if (!navigator.geolocation) {
      this.setState({ isLocation: false });
      return;
    }
  
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        this.setState(
          {
            isLocation: true,
            userLatitude: latitude,
            userLongitude: longitude,
            mapCenter: { lat: latitude, lng: longitude },
          },
          this.getLocationList
        );
      },
      (error) => {
        this.setState({ isLocation: false }, this.getAdvancedSearchList);
      }
    );
  };
  

  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleSearchChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value });
  };
  handleSearchChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ changeLocation: event.target.value });
  };

  handleChangedTab = (event: any, newValue: string) => {
    this.setState({ tabValue: newValue, subCategory:""}, () => {
      this.getAdvancedSearchList();
    });
  };

  handleSubCatChange = (event: any) => {
    this.setState({ subCategory:event.target.value }, () => {
      this.getAdvancedSearchList();
    });
  };

  handleChangeActiveTab = ( event: any, newValue: number) => {
    this.setState({ activeTab: newValue });
  };

  handleMarkerClick = (element:any) => {
    this.setState({
      selectedBar: element,
      mapCenter: {
        lat: element.latitude,
        lng: element.longitude,
      },
    });
  };
  setSelectedElement= () => {
    this.setState({selectedBar: null})
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  

  getAllList = () =>{

    this.setState({isLoading:true})
    const currentUserId = window.localStorage.getItem("userId");

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllSearchApiEndPoint}?current_user_id=${currentUserId}&liked_acc=${this.state.likedAcc}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getFilterItems = () =>{

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fiterItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFiltersEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  getLocationList = () =>{
    this.setState({isLoading:true})
    const currentUserId = window.localStorage.getItem("userId");

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?latitude=${this.state.userLatitude}&longitude=${this.state.userLongitude}&liked_acc=${this.state.likedAcc}&current_user_id=${currentUserId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getNearbyList = () =>{
    this.setState({isLoading:true})
    const currentUserId = window.localStorage.getItem("userId");

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNearByLocation}?latitude=${this.state.userLatitude}&longitude=${this.state.userLongitude}&liked_acc=${this.state.likedAcc}&current_user_id=${currentUserId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getAdvancedSearchList = () => {

    this.setState({isLoading:true})
    const currentUserId = window.localStorage.getItem("userId");

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    const importantFeatures = this.state.important_feature.map(feature => `${feature}`).join(',');

    if(this.state.isLocation) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAdvancedSearchApiEndPoint}?business_name=${this.state.changeLocation}&city_state=${this.state.search}&sub_category=${this.state.subCategory}&category=${this.state.tabValue}&filter_icons=${importantFeatures}&latitude=${this.state.userLatitude}&longitude=${this.state.userLongitude}&liked_acc=${this.state.likedAcc}&current_user_id=${currentUserId}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAdvancedSearchApiEndPoint}?business_name=${this.state.changeLocation}&city_state=${this.state.search}&sub_category=${this.state.subCategory}&category=${this.state.tabValue}&filter_icons=${importantFeatures}&liked_acc=${this.state.likedAcc}&current_user_id=${currentUserId}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();
  };

  getCategoryIconUrl = (category: string) => {
    switch (category) {
      case 'Bar':
        return barIcon;
      case 'Winery':
        return wineryIcon;
      case 'Restaurant':
        return restaurantIcon;
      case 'Brewery':
        return breweryIcon;
    }
  }

  goToCatalogue = (id:string) => {
    this.getProfileViewed(id);
    this.props.navigation.navigate("Catalogue",{id:id})
  };

  getProfileViewed = (id:string) => {

      const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.profileViewedCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getViewedProfileInfo}?id=${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  getCategorySubcategoryData = () => {

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCatSubcatApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategorySubcategory}?name=${this.state.tabValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  redirectBack = () =>{
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
