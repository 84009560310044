Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.editApiMethodType = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.getEventDataAPiEndPoint = "bx_block_calendar/booked_slots/show_events";
exports.addEventAPiMethod = "bx_block_calendar/booked_slots";
exports.deleteEventAPiEndPoint = "bx_block_calendar/booked_slots";
exports.likeBusinessEvents = "bx_block_like/likes/like_events"
exports.getShareUrlApiCallId = "bx_block_share/share/share_data"
exports.addToCalendar = "bx_block_share/share/add_to_calendar";
// Customizable Area End