import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    CardMedia,
    CardContent,
    Grid,
    IconButton,
    InputBase,
    Button,
    CircularProgress, Backdrop
} from "@material-ui/core";
import { Dialog , DialogActions,Snackbar, Alert} from "@mui/material";
import Header from "./Header.web";
import NavigationMenu from "./NavigationMenu.web";
import "./NavigationMenu.css";

import TravelBlogArticleController, {
    Props,
} from "./TravelBlogArticleController";
import Share from "../../share/src/Share.web";
import { searchIcon, shareBtnBlog, btnLeft, btnRight, shareBtnArticle, backBtnTandC, btnCancel} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
    typography: {
        "fontFamily": `"Museo Sans", sans-serif`,
    }
});

// Customizable Area End

export default class TravelBlogArticle extends TravelBlogArticleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderReadMoreForArticle(id:string) {
        return (
          <Typography
            className="blog-content-font blog-read-more-container-2"
            data-test-id="articleBlogBtn"
            gutterBottom
            onClick={() => this.getArticleData(id)}
          >
            READ MORE
          </Typography>
        );
      }

      renderReadMoreButton(id:string) {
        const { selectedBlog } = this.state;
        
        if (!selectedBlog) {
          return (
            <Typography
              className="blog-content-font blog-read-more"
              data-test-id="btnArticle"
              gutterBottom
              onClick={() => this.getArticleData(id)}
            >
              READ MORE
            </Typography>
          );
        }
      
        return null; // or return something else if needed
      }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { travelArticleData, currentIndex , selectedBlog, loading } = this.state;
        const currentBlog = travelArticleData[currentIndex];
        const getBlogDetails = (field:string) => selectedBlog ? selectedBlog[field] : currentBlog?.[field];

        const blogImage = selectedBlog?.blog_image?.url || currentBlog?.blog_image?.url;

        return (
            <ThemeProvider theme={theme}>
            <Box style={{ overflowX: "hidden" }}>
                <Header {...this.props} />   
                    <Box sx={webStyle.mainWrapperBackground} style={{
                        transition: 'background-image 0.3s ease-in-out',
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('${blogImage}')`,
                    }} >
                        <Box style={{maxWidth:1080}}>
                        <Box className={selectedBlog ? "blog-heading-container extra-spacing" : "blog-heading-container"}>
                            <Typography className="blog-category">{getBlogDetails('category')}</Typography>
                            <Typography className="blog-heading">{getBlogDetails('title')}</Typography>
                            <Typography className="blog-description" gutterBottom dangerouslySetInnerHTML={{ __html: !selectedBlog && currentBlog?.description?.split(/[\.\!]/)[0] + '.' || ' ' }} />
                            <Box className="blog-content">
                                <Box className="blog-content-1">
                                    <Typography className="blog-content-font blog-author" gutterBottom>{getBlogDetails('author_name')}</Typography>
                                    <Typography className="blog-content-font blog-date" gutterBottom>{getBlogDetails('date')}</Typography>
                                </Box>
                                {this.renderReadMoreButton(currentBlog?.id)}
                            </Box>
                            <Box className="slider-btns">
                                {!selectedBlog &&
                                    <>
                                        <Button className="prev-next-btn" onClick={this.handlePrev}><img style={{ width: "10px", height: "20px" }} src={btnLeft}></img></Button>
                                        <Button className="prev-next-btn" onClick={this.handleNext}><img style={{ width: "10px", height: "20px" }} src={btnRight}></img></Button>
                                    </>
                                }
                            </Box>
                        </Box>
                        </Box>
                    </Box>
                <Box>
                    <Backdrop
                        open={loading}
                        style={{ color: '#fff', zIndex: 1000 }}
                    >
                        <CircularProgress color="inherit" size={30} />
                    </Backdrop>
                </Box>
                <Dialog className="pop-up-dialog-catalogue" open={this.state.setOpenShare} onClose={this.handleClose}>
                    <DialogActions className="pop-up-dialog-action-catalogue">
                        <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn2" onClick={this.handleClose} color="primary">
                            <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                        </Button>
                    </DialogActions>
                    <Share {...this.props} shareUrl={this.state.shareableUrl} />
                </Dialog>
                <Box className="live-area" style={{padding:"0px 5.694vw"}}>
                <Box className="blog-container">
                    {selectedBlog ?
                        (
                            <Box>
                                <Box style={{ display: "flex", alignItems: "center", marginBottom:"1.32vw" }}>
                                    <Button className="btn-container-travel" data-test-id="gotoBtn" onClick={this.goBackToTravel} >
                                        <img src={backBtnTandC}></img>
                                    </Button>
                                    <Button data-test-id="shareBtn" onClick={()=>this.handleClickOpenSharePopup(selectedBlog.id)}> 
                                        <img className="share-btn-icon-article" src={shareBtnArticle}></img>
                                    </Button>
                                </Box>
                                <Typography className="typo-heading-blog" gutterBottom>{selectedBlog?.title}</Typography>
                                <Typography className="typo-description-blog-selected" gutterBottom>
                                    <div dangerouslySetInnerHTML={{ __html: selectedBlog?.description }} />
                                </Typography>
                                <Typography className="typo-content-blog" gutterBottom>{selectedBlog?.content}</Typography>
                            </Box>   
                        ) : (
                            <>
                            <Box className="blogs-wrapper">
                                <Grid container spacing={4} className="blog-container-2">
                                    {this.state.travelArticleData?.map((article: any) => (
                                        <Grid item md={6} xs={6} className="blog-item-img-container" key={article.id}>
                                            <CardMedia
                                                component="img"
                                                alt={article.title}
                                                image={article.blog_image?.url}
                                                className="blog-card-img"
                                            />
                                            <Box className="blog-img-content">
                                                <Box className="blog-content-1">
                                                    <Typography className="blog-content-font blog-category-container-2">{article.category}</Typography>
                                                </Box>
                                                <Button style={{ display: "flex", alignItems: "center", padding:0 }} data-test-id="shareButton"   onClick={() => this.state.loginToken ? this.handleClickOpenSharePopup(article.id) : this.pleaseLoginPopup() }> 
                                                    <img className="share-btn-icon-blog" src={shareBtnBlog}></img>
                                                </Button>
                                                <Snackbar open={this.state.loginSnackbar} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                    <Alert
                                                        severity="error"
                                                        variant="filled"
                                                        className="alert-submission-login"
                                                        sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                                                    >
                                                        Please Login!
                                                    </Alert>
                                                </Snackbar>
                                            </Box>
                                            <CardContent className="blog-card-content-container">
                                                <Typography gutterBottom className="typo-heading-blog">
                                                    {article.title}
                                                </Typography>
                                                <Typography gutterBottom className="typo-description-blog">
                                                    <div dangerouslySetInnerHTML={{ __html: article?.description?.split(/[\.\!]/)[0] + '.' || ' ' }} />
                                                </Typography>
                                                <Box className="blog-content">
                                                    <Box className="blog-content-1">
                                                        <Typography className="blog-content-font blog-author-container-2" gutterBottom>{article.author_name}</Typography>
                                                        <Typography className="blog-content-font blog-date-container-2" gutterBottom>{article.date}</Typography>
                                                    </Box>
                                                    {this.renderReadMoreForArticle(article.id)}
                                                </Box>
                                            </CardContent>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Box className="blog-container-3">
                            <Box className="blog-search">
                                <IconButton data-test-id="searchBtnBlog" type="button" aria-label="search" className="icon-btn" onClick={this.getSearchData}>
                                    <img src={searchIcon}></img>
                                </IconButton>
                                <InputBase
                                    placeholder="Search"
                                    data-test-id="searchBlog"
                                    className="search-placeholder"
                                    inputProps={{ 'aria-label': 'Search' }}
                                    value={this.state.searchChange}
                                    onChange={this.handleSearchChange}
                                />
                            </Box>
                            <Box>
                                <Typography className="blog-container-3-heading">CATEGORIES</Typography>
                            </Box>
                            <Box className="blog-container-3-categories">
                                {
                                    this.state.categoryList.map((article: any) => (
                                        <Button data-test-id="categoryList" className="category-list-btn" onClick={() => this.setCategoryList(article)}>
                                            <Typography 
                                            className={`blog-container-3-category ${this.state.categoryChange?.includes(article) ? 'selected-category' : ''}`}
                                            >{article}</Typography>
                                        </Button>
                                    ) )
                                }
                            </Box>
                            <Box >
                                <Typography className="blog-container-3-heading" >CITIES</Typography>
                            </Box>
                            <Box className="blog-container-3-cities">
                                {
                                    this.state.citiesList.map((article: any) => (
                                        <Button data-test-id="cityList" className="category-list-btn" onClick={() => this.setCityList(article)}>
                                            <Typography
                                            className={`blog-container-3-category ${this.state.cityChange?.includes(article) ? 'selected-category' : ''}`} >{article}</Typography>
                                        </Button>
                                    ) )
                                }
                            </Box>
                        </Box>
                            </>
                        )
                    }
                </Box>
                </Box>
                <NavigationMenu {...this.props} />
            </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapperBackground: {
        backgroundRepeat: "no-repeat",
        backgroundSize : "cover",
        marginTop:"5.625vw",
        height:"43.194vw",
    },
}
// Customizable Area End