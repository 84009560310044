import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON1 = require("../../../framework/src/config.js");
import { userImg }  from "./assets";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: 'string' | 'file' | 'textarea' | 'text' | 'date' | 'datetime' | 'integer' | 'number' | 'boolean' | 'float' | 'checkbox';
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

interface Image {
  id:number;
  url: string;
  type: string;
  filename: string;
  image_name:string;
  description:string;
}
interface FeatureIcon {
  url: string;
  type: string;
  filename: string;
};
interface ImportantFeature{
  id: string;
  name: string;
  active:boolean;
  icon: FeatureIcon;
};
interface CheckIn {
  business_name: string;
}
interface Account {
  avatar: {
    url: string;
  };
  business_name:string;
  city:string;
  state_name:string;
}

interface Events {
  data:{
    attributes:{
      calendar_banner:any;
      event_title: string;
      booking_date: string;
      event_type: string;
    }
  }
}

interface Badges {
  amenity_badge_icon: string;
  category_badge_icon: string;
  general_badge_icon: string;
};

interface Activity{
  name:string;
  description:string;
  bar_name:string;
  icon:string;
  created_at:string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  editMode:boolean;
  important_feature:ImportantFeature[];
  editModeGallery: boolean;
  image_library:Image[];
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;

  id:string;
  name: string,
  userName:string,
  user_email:string,
  country_code:string,
  joined_in:string,
  city_name:string,
  state_name:string,
  image_library:Image[],
  important_feature: ImportantFeature[];
  top_three_checkins:CheckIn[];
  your_tops:string[];
  avatar: File | null;
  imageUrl:string | null;
  editMode:boolean;
  dataEditedPopup: boolean;
  invalidCountryCode:string;
  invalidUserName:string,
  invalidEmail:string,
  important_categories:string[];
  likedBusinessAccounts:Account[];
  totalCheckIns:number;
  setEventOpenPopup: boolean;
  business_Id:string;
  topsError:string;
  businessEvents: Events[];
  hopperBadges:Badges;
  recentActivity:Activity[];
  activityType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,

      id:"",
      name: "",
      userName:"",
      user_email:"",
      country_code:"",
      joined_in:"",
      city_name:"",
      state_name:"",
      image_library:[],
      important_feature:[],
      top_three_checkins:[],
      your_tops:[],
      avatar:null,
      imageUrl:"",
      editMode:false,
      dataEditedPopup: false,
      invalidCountryCode:"",
      invalidUserName:"",
      invalidEmail:"",
      important_categories:[],
      likedBusinessAccounts:[],
      totalCheckIns:0,
      setEventOpenPopup: false,
      business_Id:"",
      topsError:"",
      businessEvents: [],
      hopperBadges: {
        amenity_badge_icon: "",
        category_badge_icon: "",
        general_badge_icon: "",
      },
      recentActivity: [],
      activityType:"",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getCustomizableProfile();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getCustomizableProfileCallId !== "" &&
    this.getCustomizableProfileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);

        this.setState({
          id: responseJson.accounts?.data.id,
          name: responseJson.accounts?.data.attributes.account.first_name,
          userName: responseJson.accounts?.data.attributes.account.user_name,
          user_email: responseJson.accounts?.data.attributes.account.email,
          imageUrl: responseJson.accounts?.data.attributes.avatar.url,
          country_code: responseJson.accounts?.data.attributes.account.country_code,
          joined_in: responseJson.accounts?.data.attributes.created_at,
          city_name: responseJson.accounts?.data.attributes.account.city,
          state_name: responseJson.accounts?.data.attributes.account.state_name,
          important_feature: responseJson.accounts?.data.attributes.account.important_categories,
          important_categories: responseJson.accounts?.data.attributes.account.important_feature,
          image_library: responseJson?.images,
          avatar: this.state.avatar,
          likedBusinessAccounts:responseJson.likes.like_accounts,
          totalCheckIns:responseJson.total_check_ins,
          top_three_checkins: responseJson.top_check_ins_details,
          your_tops:responseJson.accounts?.data.attributes.account.your_tops,
          businessEvents: Array.isArray(responseJson?.events) ? responseJson.events : [],
          hopperBadges: responseJson.badges,
          recentActivity: responseJson.recent_activity,
        });
        
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.updateCustomizableProfileCallId !== "" &&
    this.updateCustomizableProfileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if(responseJson.data){
          this.setState({ editMode: false, topsError:""});
          this.setState({ dataEditedPopup: true }, () => {
            setTimeout(() => {
              this.setState({ dataEditedPopup: false });
            }, 1000);
          });
        }else{
          const { error } = responseJson;
          this.setState({
            invalidCountryCode: error.country_code,
            invalidUserName: error.user_name,
            invalidEmail: error.email
          });
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleInputYourTopsChange = (value:string, index:number) => {
    const newTops = [...this.state.your_tops];
    newTops[index] = value;
    this.setState({ your_tops: newTops });
  };
  

  goToLikedBarPage = () =>{
    const currentUserId = window.localStorage.getItem("userId");
    this.props.navigation.navigate("Explore", {id:currentUserId})
  }

  getImageSrc() {
    return this.state.imageUrl || userImg;
  }

  handleEditClick = () => {
    this.setState({ editMode: true });
  };

  
  handleUploadImgChange = (event: any) => {

    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState(prevState => ({
              ...prevState,
              imageUrl: reader.result as string,
              avatar: file
        }));
        this.handleInputChange('avatar',file)
      };
      reader.readAsDataURL(file);
    }
  };

  handleInputChange = (key: string, value: string ) => {
    this.setState(prevState => ({
          ...prevState,
          [key]: value
  }));
  };

  handleIconClick = (featureName: string) => {

    this.setState((prevState) => {

      let updatedCategories = [...prevState.important_categories];
      if (updatedCategories.includes(featureName)) {
        updatedCategories = updatedCategories.filter((feature) => feature !== featureName);
      } else {
        updatedCategories.push(featureName);
      }
  
      const updatedImportantFeatures = prevState.important_feature?.map((feature) => ({
        ...feature,
        active: feature.name === featureName ? !feature.active : feature.active,
      }));
  
      return {
        important_categories: updatedCategories,
        important_feature: updatedImportantFeatures,
      };
    });
  };

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {

    let Token = window.localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: Token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    if (!this.state.your_tops.every((value) => typeof value === "string" && value.trim() !== "")) {
      this.setState({topsError:"Your tops field can't be blank"});
      return null;
    }
    let Token = window.localStorage.getItem("token")
    const header = {
      token: Token
    };

    const data = new FormData();
    data.append('your_tops', this.state.your_tops.join(","));
    data.append('email', this.state.user_email);
    data.append('user_name', this.state.userName);
    data.append('first_name', this.state.name);
    data.append('country_code', this.state.country_code);
    if(this.state.important_categories){
      data.append('important_feature', this.state.important_categories.join(','));
    }
    if(this.state.avatar){
      data.append('avatar', this.state.avatar);
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleViewAllBadges = () => {
    this.props.navigation.navigate('HopperBadges',{patronId: this.state.id})
  }

  handleEventOpened  = () =>{
    this.setState({setEventOpenPopup: true})
  }

  handleClosedEvent  = () =>{
    this.setState({setEventOpenPopup: false})
  }
  goToActivityPage = () => {
    this.props.navigation.navigate('RecentActivity',{patronId: this.state.id})
  }
  // Customizable Area End
}
