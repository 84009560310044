import React from "react";
// Customizable Area Start
import { Box, Typography, Modal,CircularProgress } from "@material-ui/core"
import { Button, Dialog, DialogActions} from "@mui/material";
import Divider from '@mui/material/Divider';
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import { createTheme, ThemeProvider, Theme } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import Share from "../../share/src/Share.web";
import {
    badge1, shareIcon,btnCancel
} from "./assets";
const theme2 = createTheme({
    typography: {
        "fontFamily": `"Museo Sans", sans-serif`,
    }
});
declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}
// Customizable Area End

import HopperBadgesController, { Props, SingleBadgeType } from "./HopperBadgesController";

export default class HopperBadges extends HopperBadgesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.sliderRef1 = React.createRef();
        this.sliderRef2 = React.createRef()
        this.sliderRef3 = React.createRef()
        // Customizable Area End
    }

    // Customizable Area Start
    sliderRef1: React.RefObject<Slider>;
    sliderRef2: React.RefObject<Slider>;
    sliderRef3: React.RefObject<Slider>;
    renderSliderBadges = (text: string, badgesData: SingleBadgeType, sliderRef: React.RefObject<Slider>) => {
        return (
            <Box style={{ maxWidth: 1080, width:"100%", marginBottom: '72px', borderBottom: !this.state.badgeName ? "1px solid #70211F4D" : 'none', paddingBottom: '20px' }}>
                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
                    <Typography>{text} ({badgesData?.total_count ? badgesData?.total_count : 0})</Typography>
                    {
                        this.state.badgeName ?
                            <Typography className="pointer" onClick={this.backViewall}>Back</Typography>
                            :
                            <Typography className="pointer" onClick={() => this.viewAllBadges(text)}>View All</Typography>
                    }
                </Box>
                {this.state.isLoading ? (
                    <Box style={{
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <CircularProgress color="inherit" size={30} />
                    </Box>
                ) : (
                    <>
                        {badgesData?.badges?.length > 0 ? (
                            this.state.badgeName ? (
                                <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
                                    {badgesData.badges.map((badge, index) => (
                                        <Box key={index} style={{ width: badgesData.badges.length === 1 ? '100%' : 'calc(50% - 10px)' }}>
                                            <BadgeBox key={index} onClick={() => this.handleShowBadgeToggle(badge)} style={{ width: "100%" }}>
                                                <Box><img src={badge.icon ? badge.icon : badge1} style={{ objectFit: 'cover', width: '8.264vw' }} /></Box>
                                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', width: "70%" }}>
                                                    <Typography>{badge.badge_name}</Typography>
                                                    <Box>
                                                        <Typography style={{ fontSize: '12px', fontWeight: 400 }}>{badge.description}</Typography>
                                                        <meter value={badge.earned ? 10 : 0.2} min="0" max="10" style={{ width: '100%' }}></meter>
                                                    </Box>
                                                </Box>
                                            </BadgeBox>
                                            <Divider flexItem className="divider-badges" />
                                        </Box>
                                    ))}
                                </Box>
                            ) : (
                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                                    <ArrowBackIosIcon style={{ fontSize: '2rem', cursor: "pointer", color: "#DA9A22" }} onClick={() => this.handleBackClick(sliderRef)} />
                                    <Box className="slider-container" sx={{ height: "max-content", maxWidth: 1080, width: "95%" }}>
                                        <Slider {...settings} ref={sliderRef}>
                                            {badgesData.badges.map((badge, index) => (
                                                <BadgeBox key={index} onClick={() => this.handleShowBadgeToggle(badge)}>
                                                    <Box><img src={badge.icon ? badge.icon : badge1} style={{ objectFit: 'cover', width: "8.264vw" }} /></Box>
                                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', width: "70%" }}>
                                                        <Typography>{badge.badge_name}</Typography>
                                                        <Box>
                                                            <Typography style={{ fontSize: '12px', fontWeight: 400 }}>{badge.description}</Typography>
                                                            <meter value={badge.earned ? 10 : 0.2} min="0" max="10" style={{ width: '100%' }}></meter>
                                                        </Box>
                                                    </Box>
                                                </BadgeBox>
                                            ))}
                                        </Slider>
                                    </Box>
                                    <ArrowForwardIosIcon style={{ fontSize: '2rem', cursor: "pointer", color: "#DA9A22" }} onClick={() => this.handleNextClick(sliderRef)} />
                                </Box>
                            )
                        ) : (
                            <Box style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Typography>No badges</Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        );
    };
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const tabs = [
            { id: "all", label: "All Badges", content: "Content for All Badges" },
            { id: "earned", label: "Earned Badges", content: "Content for Earned Badges" },
            { id: "unearned", label: "Unearned Badges", content: "Content for Unearned Badges" },
        ];
        const { hopperBadges, badgeName } = this.state
        const { "Amenity Badge": amenityBadge, "Category Badge": categoryBadge, "General Badge": generalBadge } = hopperBadges
        return (
            <ThemeProvider theme={theme2}>
                <Box  style={{ overflowX: "hidden" }}>
                    <Header {...this.props} />
                    <Box style={{ marginTop: "5.625vw", color: "#712220" }}>
                        <Box className="live-area-advance" >
                        <Box style={{ marginTop: "4vw", display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "4.2vw",marginBottom: "72px" }}>
                            <Typography style={{cursor:"pointer"}} onClick={this.goBack}>Back</Typography>
                            <Typography style={{ color: "#712220", fontWeight: 600 }}>BADGES</Typography>
                        </Box>
                        </Box>
                        <Box className="live-area" style={{ padding: "0px 4.1%", flexDirection: "column", alignItems: "center" }}>
                            <Box style={{ display: "flex", gap: "16px", marginBottom: "16px", borderRadius: '8px', boxShadow: "rgba(99, 99, 99, 0.1) 0 0.2rem 0.8rem 0", padding: '2px' }}>
                                {tabs.map((tab) => (
                                    <Box
                                        key={tab.id}
                                        data-test-id="tabChange"
                                        onClick={() => this.handleTabChange(tab.id)}
                                        style={{
                                            cursor: "pointer",
                                            fontFamily: 'Raleway',
                                            padding: "8px 32px",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.activeBadgeTab === tab.id ? "#DA9A22" : "white",
                                            color: this.state.activeBadgeTab === tab.id ? "white" : "inherit",
                                            fontWeight: this.state.activeBadgeTab === tab.id ? 800 : 700,
                                            textAlign: "center",
                                            fontSize: '14px',
                                            letterSpacing: "1.1px"
                                        }}
                                    >
                                        {tab.label}
                                    </Box>
                                ))}
                            </Box>

                            <Box style={{ marginTop: "4vw", maxWidth: "1080px", width:"100%",marginBottom: "72px" , display:"flex", flexDirection:"column", alignItems:"center"}}>
                                {badgeName ? (
                                    <>
                                        {badgeName === "General Badges" && this.renderSliderBadges(badgeName, generalBadge, this.sliderRef1)}
                                        {badgeName === "Amenity Badges" && this.renderSliderBadges(badgeName, amenityBadge, this.sliderRef2)}
                                        {badgeName === "Category Badges" && this.renderSliderBadges(badgeName, categoryBadge, this.sliderRef3)}
                                    </>
                                ) : (
                                    <>
                                        {this.renderSliderBadges("General Badges", generalBadge, this.sliderRef1)}
                                        {this.renderSliderBadges("Amenity Badges", amenityBadge, this.sliderRef2)}
                                        {this.renderSliderBadges("Category Badges", categoryBadge, this.sliderRef3)}
                                    </>
                                )}
                            </Box>
                        </Box>

                        <NavigationMenu {...this.props} />

                    </Box>
                    <Modal
                        open={this.state.isShowHopperDetail}
                        onClose={this.toggleBagdeDetailShow}
                        aria-labelledby="view-hopper-badge"
                        aria-describedby="view-hopper-badge"
                    >
                        <Box sx={badgeModalStyle}>
                            <Box display="flex" justifyContent="flex-end" mb={1} >
                                <Box onClick={this.toggleBagdeDetailShow} style={{ backgroundColor: "#712220", color: 'white', borderTopRightRadius: '0.8rem', }}>
                                    <CloseIcon style={{ height: "1.5rem", width: '1.5rem' }} />
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: 'column', marginBottom: '30px', justifyContent: 'center', alignItems: "center", padding: "0 17px" }}>
                                <Typography style={{ fontWeight: 600, color: "#712220", fontSize: '20px' }}>
                                    {this.state.singleBadgeData.badge_name}
                                </Typography>
                                <img src={this.state.singleBadgeData.icon ? this.state.singleBadgeData.icon : badge1} width="100px" height="100px" />
                                {this.state.singleBadgeData.date && <Typography>Earned on {this.state.singleBadgeData.date} </Typography>}
                                <Typography style={{ textAlign: 'center', padding: "0 10px" }}>{this.state.singleBadgeData.description}</Typography>

                            </Box>
                            <Box sx={{ marginBottom: "10px", padding: '20px' }}>
                                <div>
                                    <Button className="btn-subdirectory" data-test-id="handleClickBtn" onClick={()=>this.handleClickOpenShareBadgePopup(this.state.singleBadgeData.badge_id || 0)}>
                                        <img src={shareIcon} />
                                        <Typography style={{ color: "#712220", fontSize: '14px' }} >
                                            Share
                                        </Typography>
                                    </Button>
                                    <Dialog className="pop-up-dialog-catalogue" open={this.state.setOpenShareState} onClose={this.handleCloseBadgepopup}>
                                        <DialogActions className="pop-up-dialog-action-catalogue">
                                            <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn2" onClick={this.handleCloseBadgepopup} color="primary">
                                                <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                                            </Button>
                                        </DialogActions>
                                        <Share {...this.props} shareUrl={this.state.shareUrl} />
                                    </Dialog>
                                </div>
                            </Box>
                        </Box>
                    </Modal>
                </Box>

            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const BadgeBox = styled(Box)({
    display: 'inline-flex !important',
    gap: '20px',
})
const badgeModalStyle = {
    position: 'absolute',
    border: "none",
    borderRadius: '0.8rem',
    boxShadow: 2,
    transform: 'translate(-50%, -50%)',
    width: '20rem',
    top: '50%',
    left: '50%',
    bgcolor: 'background.paper',
};
// Customizable Area End