import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON1 = require("../../../framework/src/config.js");

interface Activity{
  name:string;
  description:string;
  bar_name:string;
  icon:string;
  created_at:string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  recentActivity:Activity[];
  activityType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecentActivityController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
 
  getActivityByTypeCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      recentActivity: [],
      activityType:"",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTypeActivity("all","1");
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getActivityByTypeCallId !== "" &&
    this.getActivityByTypeCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if(responseJson){
          this.setState({ recentActivity: responseJson});
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  goBack = () =>{
    this.props.navigation.goBack()
  }

  getTypeActivity = (type:string, id:string) => {

    this.setState({activityType:id})

    const accountId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const header = {
      "Content-Type": configJSON.searchApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getActivityByTypeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getActivityByType}?type=${type}&account_id=${accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
