import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface Events {
   attributes:{
     event_day: string;
     date_of_booking: string;
     start_time: string;
     event_type: string;
   }
}

interface FeatureIcon {
  url: string;
  type: string;
  filename: string;
};
interface ImportantFeature{
  id: string;
  name: string;
  active:boolean;
  icon: FeatureIcon;
};
interface MoreInfo {
  name: string;
  value: number | boolean | string | string[];
}

interface Image1 {
  id:number;
  url: string;
  type: string;
  filename: string;
  image_name:string;
  description:string;
}

interface Badges {
  amenity_badge_icon: string;
  category_badge_icon: string;
  general_badge_icon: string;
};
interface Events1 {
  attributes:{
    calendar_banner:string[];
    event_title: string;
    booking_date: string;
    event_type: string;
  }
}

interface Activity{
  name:string;
  description:string;
  bar_name:string;
  icon:string;
  created_at:string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  shareUrl:string;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  businessDetails:any;
  helpUsHelpYouDetails:any;
  image_library:any;
  businessEvents: Events[];
  businessEvents1: Events1[];
  currentIndex: number;
  eventsPerPage: number;
  id:string;
  subCategoryName:string[];
  happyHours1:any;
  happyHours2:any;
  hoursValue:string;
  setOpen:boolean;
  setOpenShare:boolean;
  setOpenReport:boolean;
  flagReason:string,
  flagDescription:string;
  businessLogo:string;
  businessBackground:string;
  loginToken:string;
  businessLiked:boolean;
  loginSnackbar:boolean;
  successSnackbarFlag:boolean;
  shareUrl:string;
  important_feature: ImportantFeature[];
  more_info: MoreInfo[];
  setEventOpen:boolean;
  business_Id:string;
  otherAmenities:string;
  isLoading:boolean;
  isFree:boolean;
  initialMenuFile:Image1[];
  setOpenMenuView:boolean;
  currentPhotoIndex:number;
  selectedPhotoIndex:number;
  imgTitle:string;
  menuEventsPerPage:number;
  hopperBadges:Badges;
  recentActivity:Activity[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      id:"",
      businessDetails:[],
      helpUsHelpYouDetails:[],
      businessEvents: [],
      image_library:[],
      currentIndex: 0,
      eventsPerPage: 4,
      menuEventsPerPage:5,
      subCategoryName:[],
      happyHours1:[],
      happyHours2:[],
      hoursValue:"1",
      setOpen:false,
      setOpenShare:false,
      setOpenReport:false,
      flagReason:"",
      flagDescription:"", 
      businessLogo:"",
      businessBackground:"",
      loginToken:"",
      businessLiked:false,
      loginSnackbar:false,
      successSnackbarFlag:false,
      shareUrl:"",
      important_feature:[],
      more_info:[],
      setEventOpen:false,
      business_Id:'',
      otherAmenities:"",
      isLoading:false,
      isFree:false,
      initialMenuFile:[],
      setOpenMenuView:false,
      currentPhotoIndex:0,
      selectedPhotoIndex:0,
      imgTitle:"",
      businessEvents1: [],
      hopperBadges: {
        amenity_badge_icon: "",
        category_badge_icon: "",
        general_badge_icon: "",
      },
      recentActivity:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const token = window.localStorage.getItem("token")
    if (token){
      this.setState({loginToken: token})
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      // this.getListRequest(token);
        this.getBusinessDetail();
    }

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductListApiCallId != null &&
      this.getProductListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
  
      if (responseJson && !responseJson.errors) {
        this.setState({
          arrayHolder: responseJson.data,
          businessEvents: responseJson.events.data,
          businessDetails: responseJson.accounts.data.attributes.account,
          helpUsHelpYouDetails: responseJson.accounts.data.attributes.account.help_us_help_you,
          image_library: responseJson.images,
          subCategoryName: [ ...responseJson.accounts.data.attributes.account.help_us_help_you.sub_catgory_name, responseJson.accounts.data.attributes.account.help_us_help_you.others],
          happyHours1: responseJson.accounts.data.attributes.account.help_us_help_you.schedule_1,
          happyHours2: responseJson.accounts.data.attributes.account.help_us_help_you.schedule_2,
          businessLogo:  responseJson.accounts.data.attributes.avatar.url ,
          businessBackground: responseJson.accounts.data.attributes.profile_image.url,
          businessLiked:responseJson.like,
          isLoading:false,
          important_feature: responseJson.accounts.data.attributes.account.custom_field.your_filters,
          otherAmenities :responseJson.accounts.data.attributes.account.help_us_help_you.other_amenities,
          isFree: responseJson.accounts.data.attributes.subscription.subscription_setting.is_free,
          initialMenuFile:responseJson.menu,
          hopperBadges: responseJson.badges,
          recentActivity: responseJson.recent_activity,
        });
        
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.reportBusinessCallId != null &&
      this.reportBusinessCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.errors) {
        this.setState({ successSnackbarFlag: true, setOpenReport: false,});
        setTimeout(() => {
          this.setState({ successSnackbarFlag: false });
        }, 2000);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getShareUrlApiCallId != null &&
      this.getShareUrlApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.errors) {
        this.setState({ shareUrl: responseJson.link });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getMoreInfoApiCallId != null &&
      this.getMoreInfoApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.errors) {
        this.setState({ 
          more_info: [
              ...responseJson.important_features, 
              ...(this.state.otherAmenities ? [{ name: this.state.otherAmenities, value: true }] : [])
            ] 
      });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  likeBusinessCallId: string = "";
  reportBusinessCallId: string = "";
  getShareUrlApiCallId: string = "";
  getMoreInfoApiCallId: string = "";
  getProductListApiCallId:string = "";


  likedBusinessAccount = () => {

    let Token = window.localStorage.getItem("token");
    
    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const header = {
      token: Token
    };

    const formData = new FormData();

    if(business_Id){
      formData.append("likeable_id", business_Id);
    }
    formData.append("active_like", this.state.businessLiked.toString());

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.likeBusinessCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.businessLikeEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  reportBusinessAccount = () => {

    let Token = window.localStorage.getItem("token");
    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const header = {
      token: Token
    };

    const formData = new FormData();

    if(business_Id){
      formData.append("receiver_id", business_Id);
    }
    formData.append("reason", this.state.flagReason);
    formData.append("description", this.state.flagDescription);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reportBusinessCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportAccountEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleLikeChange = () => {
    this.setState(
      { businessLiked: !this.state.businessLiked },
      () => {
        this.likedBusinessAccount();
      }
    );    
  };

  pleaseLoginPopup = () => {
    this.setState({ loginSnackbar: true }, () => {
      setTimeout(() => {
        this.setState({ loginSnackbar: false });
      }, 2000);
    });
  };

  handlePrevClick = () => {
    this.setState((prevState) => ({
      currentIndex: Math.max(prevState.currentIndex - 1, 0),
    }));
  };

  handleNextClick = () => {
    this.setState((prevState) => ({
      currentIndex: Math.min(
        prevState.currentIndex + 1,
        prevState.businessEvents.length - 1
      ),
    }));
  };

  getBusinessDetail = () => {

    this.setState({isLoading:true})
    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    const currentUserId = window.localStorage.getItem("userId") || "";
    if(business_Id){
      this.setState({business_Id:business_Id})
    }

    const header = {
      "Content-Type": configJSON.productApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.businessDataAPIEndpoint}?id=${business_Id}&current_user_id=${currentUserId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMoreInfoDetail = () => {

    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const header = {
      "Content-Type": configJSON.productApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMoreInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.moreInfoDataEndPoint}?id=${business_Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getShareUrlFunc = () =>{
    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    const currentUserId = window.localStorage.getItem("userId");

    const header = {
      "Content-Type": configJSON.productApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShareUrlApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShareUrlEndPoint}?id=${business_Id}&type=Catalogue&current_user_id=${currentUserId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEventOpen  = () =>{
    this.setState({setEventOpen: true})
  }

  handleHourChange = (event: React.SyntheticEvent, newValue: string) => {
    this.setState({hoursValue:newValue})
  };

  handleClickOpen = () => {
    this.setState({ setOpen: true }, () => {
      this.getMoreInfoDetail();
    });    
  };

  handleClickOpenSharePopup = () => {
    this.setState({setOpenShare:true})
    this.getShareUrlFunc();
  };

  handleClickOpenReport = () => {
    this.setState({setOpenReport:true})
  };

  handleClose = () => {
    this.setState({setOpen:false})
    this.setState({setOpenShare:false})
    this.setState({setOpenReport:false})
    this.setState({setEventOpen: false})
  };

  handleDescriptionChangeDirectory = (text: string) => {
    this.setState({flagDescription: text});
  };

  handleFlagReasonChange = (value: string) => {
    this.setState({ flagReason:value });
  };

  viewMenuPopup = () => {
    this.setState({setOpenMenuView: true})
  }

  handleClickCloseMenu = () => {
    this.setState({setOpenMenuView: false})
  }


  updateMenuImage = (direction:any) => {
    this.setState((prevState) => {
      const { currentPhotoIndex, currentIndex, menuEventsPerPage, initialMenuFile } = prevState;
      const displayedEvents = initialMenuFile.slice(currentIndex, currentIndex + menuEventsPerPage);
  
      let newIndex = currentPhotoIndex;
      if (direction === "prev") {
        newIndex = currentPhotoIndex - 1 < currentIndex ? currentIndex : currentPhotoIndex - 1;
      } else if (direction === "next") {
        newIndex = currentPhotoIndex + 1 > currentIndex + displayedEvents.length - 1
          ? currentIndex + displayedEvents.length - 1
          : currentPhotoIndex + 1;
      }
  
      return {
        currentPhotoIndex: newIndex,
        selectedPhotoIndex: newIndex,
        imgTitle: initialMenuFile[newIndex]?.filename || "",
      };
    });
  };
  
  handlePrevMenuImg = () => {
    this.updateMenuImage("prev");
  };
  
  handleNextMenuImg = () => {
    this.updateMenuImage("next");
  };
   

  handleMenuImageClick = (index: number) => {
    this.setState({ currentPhotoIndex: index, selectedPhotoIndex: index, imgTitle:this.state.initialMenuFile[index]?.filename  });
  };
  
  handlePreviousMenuClick = () => {
    this.setState((prevState) => {
      const newIndex = Math.max(prevState.currentIndex - 5, 0);
      return {
        currentIndex: newIndex,
        currentPhotoIndex: newIndex,
        selectedPhotoIndex: newIndex,
        imgTitle:this.state.initialMenuFile[newIndex]?.filename,
      };
    });
  };  

  handleClickMenuNext = () => {
    this.setState((prevState) => {
      const newIndex = Math.min(prevState.currentIndex + 5, prevState.initialMenuFile.length - 1);
      return {
        currentIndex: newIndex,
        currentPhotoIndex: newIndex,
        selectedPhotoIndex: newIndex,
        imgTitle:this.state.initialMenuFile[newIndex]?.filename,
      };
    });
  };

  handleViewAllBadges = () => {
    const currentUserId = window.localStorage.getItem("userId") ||  location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    this.props.navigation.navigate('HopperBadges',{patronId: currentUserId})
  }

  goToActivityPage = () => {
    this.props.navigation.navigate('RecentActivity',{patronId: location.pathname.substring(location.pathname.lastIndexOf('/') + 1)})
  }

  getFullAddress = () => {
    const { address, city, state_name, country_code } = this.state.businessDetails;
  
    const parts = [address, city, state_name, country_code];
    const fullAddress = parts.filter(Boolean).join(", ");
  
    return fullAddress;
  };  

  handleAddressClick = () => {
    const fullAddress = this.getFullAddress();
    const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(fullAddress)}`;
    window.open(googleMapsUrl, "_blank");
  };

  // Customizable Area End
}
