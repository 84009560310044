import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Modal,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import {
  leftArrow,
  RightArrow,
  CloseBtn,
} from "./assets";
import { CheckBox, CheckBoxTwoTone } from "@mui/icons-material";
import {
  Typography,
} from "@material-ui/core";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  }
});
import { ImageListType } from "./types";
// Customizable Area End

import PhotoLibraryController, {
  Props,
  configJSON,
} from "./PhotoLibraryController";

// Customizable Area Start
// Customizable Area End

export default class PhotoLibrary extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    const { currentPhotoIndex , currentIndex, eventsPerPage, selectedPhotoIndex} = this.state;
    const currentImage = this.state.image_library[currentPhotoIndex];
    const displayedEvents = this.state.image_library.slice( currentIndex, currentIndex + eventsPerPage );
    const imagePlaceholders = Array.from({ length: 5 - displayedEvents.length }); 
    return (
        <ThemeProvider theme={theme}>
            <Box className="itemHeadingSeeAll">
                <Typography style={webStyles.itemHeading}>Image Library</Typography>
                <Typography style={webStyles.itemHeading2}  onClick={this.handleClickOpen}>See all</Typography>
            </Box>
            <Dialog className="pop-up-dialog-barowner" open={this.state.setOpen} onClose={this.handleClickClose}>
              <DialogTitle id="customized-dialog-title" className="upload-popup-title">
                {currentImage?.filename}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.handleClickClose}
                sx={{
                  position: 'absolute',
                  right: "0.556vh",
                  padding: 0,
                  top: "0.556vh",
                }}
              >
                <img className="upload-popup-close" src={CloseBtn}></img>
              </IconButton>
              <DialogContent className="popup-content-wrapper">
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Button className="no-style-btn" onClick={this.handlePrevImage} disabled={currentPhotoIndex === currentIndex}>
                    <img className="popup-arrow-btn left-btn" src={leftArrow}></img>
                  </Button>
                  {
                    currentImage ? <img src={currentImage?.url} alt={currentImage?.filename} className="image-center-popup" /> :
                      <div className="image-center-popup-placeholder">Add an image</div>
                  }
                  <Button className="no-style-btn" onClick={this.handleNextImage} disabled={currentPhotoIndex === currentIndex + displayedEvents.length - 1}>
                    <img className="popup-arrow-btn right-btn" src={RightArrow}></img>
                  </Button>
                </Box>
                <Box className="popup-content-grid-wrapper">
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <Button className="no-style-btn" onClick={this.handlePreviousClick} disabled={currentIndex === 0}>
                      <img className="popup-arrow-btn" src={leftArrow}></img>
                    </Button>
                    <Box className="uploadbtn-imggrid-wrapper">
                      {displayedEvents.map((event, index) => (
                        <img
                          key={index}
                          src={event?.url}
                          alt={event?.filename}
                          className="image-grid-popup"
                          data-test-id="imageClick"
                          style={{ border: selectedPhotoIndex === index + currentIndex ? '4px solid #DA9A22' : 'none' }}
                          onClick={() => this.handleImageClick(index + currentIndex)}
                        />
                      ))}
                      {imagePlaceholders.map((_, index) => (
                        <Box className="image-grid-popup-placeholder" key={index}></Box>
                      ))}
                    </Box>
                    <Button className="no-style-btn" onClick={this.handleClickNext} disabled={currentIndex >= this.state.image_library.length - eventsPerPage}>
                      <img className="popup-arrow-btn" src={RightArrow}></img>
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  itemHeading:{
    color:"#712220",
    fontWeight:700,
    fontSize:"1.112vw",
    fontFamily: 'Museo Sans", sans-serif',
  },
  itemHeading2:{
    color:"#712220",
    fontWeight:600,
    fontSize:"0.972vw",
    fontFamily: 'Museo Sans", sans-serif',
    cursor:"pointer"
  },
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 10,
  },
  modalRowViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  imageList: {
    width: "100%",
    height: "100%",
  },
  imgStyle: {
    maxHeight: "400px",
    maxWidth: "400px",
    width: "auto",
    height: "auto",
    margin: 10,
  },
  imageListBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  btnCheck: {
    color: "white",
  },
  errorMsg: {
    color: "red",
  },
};
// Customizable Area End
