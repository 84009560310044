import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider, Theme } from "@material-ui/core/styles";
import "@mui/styles";
import { userImg, }  from "./assets";
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import "./CustomisableUserProfiles.css";
import Divider from '@mui/material/Divider';

const theme2 = createTheme({
  typography: {
    "fontFamily": `"Museo Sans", sans-serif`,
  }
});
// Customizable Area End

import RecentActivityController, {
  Props,
} from "./RecentActivityController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class RecentActivity extends RecentActivityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    const activityName = [
      { id: '1', name: 'ALL', type: 'all' },
      { id: '2', name: 'EARNED BADGES', type: 'earn_badges' },
      { id: '3', name: 'SHARED BARS', type: 'shared_bars' },
      { id: '4', name: 'LIKED BARS', type: 'liked_bars' },
      { id: '5', name: 'EVENT LIKES', type: 'event_likes' },
      { id: '6', name: 'CHECK INS', type: 'check_ins' },
      { id: '7', name: 'SHARED EVENTS', type: 'shared_events' },
      { id: '8', name: 'SAVED TO CALENDAR', type: 'add_to_calendar' },
      { id: '9', name: 'LIKED BAR ACTIVITY', type: 'liked_bar_activity' },
    ];
    
    return (
      <ThemeProvider theme={theme2}>
        <Box style={{marginTop:"5.625vw", overflowX:"hidden"}}>
        <Header {...this.props}/>
            <Box className="live-area">
                <Box style={{display:"flex", flexDirection:"column", paddingBottom:"4.861vw"}}>
                    <Box style={{ marginTop: "3.819vw", display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "4.2vw", marginBottom: "2.847vw" }}>
                        <Typography style={{ cursor: "pointer",color: "#712220", fontSize:'1.111vw' }} onClick={this.goBack}>Back</Typography>
                        <Typography style={{ color: "#712220", fontWeight: 600, fontSize:"1.25vw", letterSpacing:2 }}>RECENT ACTIVITY</Typography>  
                    </Box>
                    <Box style={{display:"flex"}}>
                        <Box style={{gap: '2.5vw', display: 'flex', flexDirection: 'column' , marginRight:"4.861vw"}}>
                            {
                                activityName.map((name) => (
                                    <Typography data-test-id="getType" key={name.id} onClick={()=>this.getTypeActivity(name.type, name.id)} style={{ color: this.state.activityType === name.id ? "#712220" : "#C33836", fontWeight:600, letterSpacing:2, fontSize:'0.972vw' , cursor:'pointer'}}>{name.name}</Typography>
                                ))
                            }
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box className="recent-activity-container" style={{height:"50.347vw", overflowX:"scroll"}}>
                            {this.state.recentActivity?.map((activity, index) => (
                                <Paper className="eventActivityContainer" key={index}>
                                    <img className="liked-img" src={activity.icon || userImg} alt={`Friend ${index + 1}`} />
                                    <Box style={{ marginLeft: "0.833vw" }}>
                                        <Typography noWrap className="event-acitivity-name">{activity.name}</Typography>
                                        <Typography gutterBottom noWrap className="event-acitivity-type">{activity.description}</Typography>
                                        <Box>
                                            <Typography noWrap className="event-acitivity-date">
                                                {activity.bar_name} <span style={{ display: "inline-flex", alignItems: "center", justifyContent: "center", marginTop: "-1.667vw" }}><b>.</b></span> {activity.created_at}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        <NavigationMenu {...this.props}/>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
 
}
// Customizable Area End
