import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    TextField,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    RadioGroup,
    Tabs,
    Tab,
    Snackbar,
    Alert,
    Dialog, DialogActions, DialogContent , DialogTitle,
    Checkbox,
    Grid
} from "@mui/material";
import Header from "../../navigationmenu/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
import {
    starIcon
} from "./assets";
import "./CustomForm.css";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Typography, Backdrop, CircularProgress } from "@material-ui/core";

const theme = createTheme({
    overrides: {
        MuiRadio: {
            root: {
                padding: "0px 7.5px 0px 10px"
            }
        }
    },
    typography: {
        "fontFamily": `"Museo Sans", sans-serif`,
    },
});
// Customizable Area End

import CustomFormController, {
    Props,
    Weekday
} from "./CustomFormController.web";

export default class BusinessSignUpForm extends CustomFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    formatLabel(category:string) {
        const index = category.indexOf('(');
        if (index !== -1) {
            const part1 = category.substring(0, index);
            const part2 = category.substring(index);
            return (
                <>
                    {part1}<br/>
                    <label>{part2}</label>
                </>
            );
        }
        return category;
    }

    isSubmitDisabled = (briefDescription:string,selectedCategory:string) => {
        return !(briefDescription && selectedCategory && selectedCategory !== "all");
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        
        const {categoryData, subCategoryData, establishmentOffer1Data, establishmentOffer2Data, establishment_offer1, openingDays, setTimes, displayedTimes, popupSubmit, displayedTimes2, setTimes2, popupSubmit2, openingDays2} = this.state;
        const weekdays: Weekday[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        return (
            <ThemeProvider theme={theme}>
                <Box style={{ overflowX: "hidden" }} >
                    <Header {...this.props} />
                    <Box style={webStyle.mainContainer}>
                        <Box style={webStyle.formContainer}>
                            <form>
                                <Box style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography style={webStyle.grid2Heading}>
                                        Help Us Help You
                                    </Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img className="start-icon-size" src={starIcon} />
                                    <Typography style={webStyle.starTextStyle}>
                                        Select all that apply
                                    </Typography>
                                </Box>
                                <Box className="category-1">
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="f"
                                        name="radio-buttons-group"
                                        onChange={this.handleCategoryChange}
                                    >
                                        {
                                            categoryData.map((categories, index) =>
                                                <FormControlLabel
                                                    key={index} 
                                                    value={categories}
                                                    className="label-style"
                                                    control={<Radio  
                                                    icon={<span className="radioBox1" />}
                                                    checkedIcon={<span className="radioBox1 checked" />} 
                                                    style={webStyle.radioBox1} />}
                                                    label={categories}
                                                    style={webStyle.radioBtnTextStyle}
                                            />
                                            )
                                        }
                                    </RadioGroup>
                                </Box>
                                <Box className="category-2">
                                    <Typography style={webStyle.starTextStyle}>
                                        Select any Sub Categories that apply(Advance Search)
                                    </Typography>
                                </Box>
                                <Box className={subCategoryData.length <= 8 ? "category-2-1-new":"category-2-1"}>
                                    {
                                        subCategoryData.map((subCategories,index) =>
                                            <Box className="category-2-1-box" key={subCategories.name}>
                                                <FormControlLabel
                                                    key={index} 
                                                    data-test-id={subCategories.name}
                                                    value={subCategories.name}
                                                    className="label-style"
                                                    onClick={()=>this.handleSubCategoryChange(subCategories.name)}
                                                    control={<Radio 
                                                        icon={<span className="radioBox1" />}
                                                        checkedIcon={<span className="radioBox1 checked" />}   
                                                        checked={this.state.subSelectedCategories.includes(subCategories.name)}
                                                        style={webStyle.radioBox}  />}
                                                    label={subCategories.name}
                                                />
                                            </Box>
                                        )
                                    }
                                </Box>
                                <Box className="category-2-1-other">
                                    <FormControlLabel
                                        value="Other"
                                        className="label-style"
                                        control={<Radio
                                            icon={<span className="radioBox1" />}
                                            checkedIcon={<span className="radioBox1 checked" />} 
                                            style={webStyle.radioBox}
                                         />}
                                        label="Others"
                                    />
                                    <TextField className="other-textfield" id="outlined-basic" variant="outlined" size="small" placeholder="Enter" name="other" value={this.state.otherCategory}
                                        data-test-id="otherCategoryLabel"
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                              height: "3.125vw",
                                              width:"13.264vw",
                                              "& fieldset": {
                                                borderColor: "#712220", 
                                              },
                                              "&:hover fieldset": {
                                                borderColor: "#712220",
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: "#712220",
                                              },
                                              "& .MuiOutlinedInput-input": {
                                                fontSize: "1.111vw",
                                              },
                                            },
                                          }}
                                        onChange={(event) => this.handleOtherCategoryChange(event.target.value)}
                                    />
                                </Box>
                                <Box className="category-3-4-5-container">
                                    <Box >
                                        <Box className="category-3">
                                            <img className="start-icon-size" src={starIcon} />
                                            <Typography style={webStyle.starTextStyle}>
                                                Select What Your Establishment Offers
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box className="category-3-1">
                                                {establishmentOffer1Data.map((item) => (
                                                    <Box className="category-3-1-formlabel" key={item.id}>
                                                            <FormControlLabel
                                                                value={item.name}
                                                                className="label-style"
                                                                data-test-id={item.name}
                                                                onClick={() => {
                                                                    const currentValue = this.state.establishment_offer1[item.name]?.[0];
                                                                    const newValue = currentValue === item.name ? '' : item.name;
                                                                    this.onFormControlChange3(item.name, [newValue, this.state.establishment_offer1[item.name]?.[1] || '']);
                                                                }}
                                                                control={
                                                                    <Radio
                                                                        icon={<span className="radioBox1" />}
                                                                        checkedIcon={<span className="radioBox1 checked" />}
                                                                        style={webStyle.radioBox}
                                                                        checked={this.state.establishment_offer1[item.name]?.[0] === item.name}
                                                                    />
                                                                }
                                                                label={item.name}
                                                            />
                                               
                                                        {item.name === 'Other' ? (
                                                            <TextField
                                                                className="other-textfield"
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                sx={{
                                                                    "& .MuiOutlinedInput-root": {
                                                                      height: "3.125vw",
                                                                      width:"13.264vw",
                                                                      "& fieldset": {
                                                                        borderColor: "#712220", 
                                                                      },
                                                                      "&:hover fieldset": {
                                                                        borderColor: "#712220",
                                                                      },
                                                                      "&.Mui-focused fieldset": {
                                                                        borderColor: "#712220",
                                                                      },
                                                                      "& .MuiOutlinedInput-input": {
                                                                        fontSize: "1.111vw",
                                                                      },
                                                                    },
                                                                  }}
                                                                size="small"
                                                                placeholder="Enter here..."
                                                                name="other"
                                                                value={this.state.otherOffer}
                                                                data-test-id="otherCategoryOffer"
                                                                onChange={(event) => this.handleOtherCategoryChange2(event.target.value)}
                                                            />
                                                        ) : (
                                                            <Select
                                                                style={webStyle.dropDownStyle}
                                                                variant="outlined"
                                                                sx={{
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: "#712220",
                                                                      },
                                                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: "#712220",
                                                                      },
                                                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: "#712220",
                                                                      },
                                                                      "& .MuiInputBase-input": {
                                                                        fontFamily: 'Museo Sans, sans-serif',
                                                                      },
                                                                  }}
                                                                className="establishment-offer-dropdown"
                                                                data-test-id={item.id}
                                                                labelId={`select-label-${item.id}`}
                                                                onChange={(event) => {
                                                                    this.onFormControlChange3(item.name, [establishment_offer1[item.name]?.[0] || '', event.target.value]);
                                                                }}
                                                                multiple={item.value_type === 'String'}
                                                                value={
                                                                    item.value_type === 'String'
                                                                        ? establishment_offer1[item.name]?.[1] || []
                                                                        : establishment_offer1[item.name]?.[1] || ''
                                                                }
                                                            >
                                                                {Array.isArray(item.selection_options)
                                                                    ? item.selection_options.map((option, index) => (
                                                                        <MenuItem className="menu-item-establishment" key={index} value={option}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))
                                                                    : item.selection_options.split(', ').map((option, index) => {
                                                                        const displayOption = isNaN(Number(option)) ? option : Number(option);
                                                                        return (
                                                                            <MenuItem className="menu-item-establishment" key={index} value={displayOption}>
                                                                                {displayOption}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                            </Select>
                                                        )}
                                                    </Box>
                                                ))}
                                            </Box>
                                            <Box className="category-3-3">
                                                <Box className="category-3-3-1">
                                                    {
                                                        establishmentOffer2Data.map(category => (
                                                            <div className="category-item" key={category}>
                                                                    <FormControlLabel
                                                                        value={category}
                                                                        className="label-style"
                                                                        data-test-id={category}
                                                                        onClick={() => this.onFormControlChange(category)}
                                                                        control={<Radio 
                                                                            checked={this.state.establishment_offer2.includes(category)}
                                                                            icon={<span className="radioBox1" />}
                                                                            checkedIcon={<span className="radioBox1 checked" />} 
                                                                            style={webStyle.radioBox} 
                                                                        />}
                                                                        label={this.formatLabel(category)}
                                                                    />
                                                                
                                                            </div>
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                            <Box className="category-4" >
                                                <img className="start-icon-size" src={starIcon} />
                                                <Typography style={webStyle.starTextStyle}>
                                                    Brief Description
                                                </Typography>
                                            </Box>
                                            <Box >
                                                <TextField
                                                    data-test-id="textAreaLabelNew"
                                                    multiline
                                                    variant="outlined"
                                                    className="text-area-style"
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            height: "16.389vw",
                                                            width: "55.278vw",
                                                            alignItems:"start",
                                                          "& fieldset": {
                                                            borderColor: "#712220", 
                                                          },
                                                          "&:hover fieldset": {
                                                            borderColor: "#712220",
                                                          },
                                                          "&.Mui-focused fieldset": {
                                                            borderColor: "#712220",
                                                          },
                                                        },
                                                      }}
                                                    placeholder="   150 characters or less"
                                                    inputProps={{ maxLength: 150 }}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        if (value.length <= 150) {
                                                          this.handleDescriptionChange(value);
                                                        }
                                                      }}                                           
                                                    />
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box className="category-5-container">
                                        <Box className="category-5-container-1">
                                            <Box className="category-5">
                                                <Box className="category-5-1">
                                                    <Typography style={webStyle.starTextStyle2}>
                                                        Hours of Operations
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {weekdays.map((day) => (
                                                        <Box className="category-5-2" key={day}>
                                                            <FormControlLabel
                                                                value={day}
                                                                className="label-style"
                                                                control={<Radio icon={<span className="radioBox1" />} checkedIcon={<span className="radioBox1 checked" />} style={webStyle.radioBox1} />}
                                                                label={day}
                                                            />
                                                            <Box className="category-5-btn-container">
                                                                {displayedTimes[day].closed ? (
                                                                    <>
                                                                        <Button className="category-5-btn font" data-test-id={displayedTimes[day].closesAt} onClick={() => this.handleClickOpenWeekdayPopup()}>
                                                                            Open
                                                                        </Button>
                                                                        <Button className={popupSubmit?"category-5-btn-clicked font":"category-5-btn font"}>
                                                                            Close
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    <Button className="category-5-btn-time">
                                                                        {`${displayedTimes[day].opensAt} - ${displayedTimes[day].closesAt}`}
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                    <Dialog className="popup-hours" open={this.state.openPopup} onClose={this.handleClosePopup}>
                                                        <DialogTitle className="popup-heading-customform"> {this.state.openCloseError && <div className="invalid-time-error">Please fill both opening and closing times for all selected days!</div>} {"Hour of Operation"}</DialogTitle>                                                        <DialogContent className="popup-hours-content">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <Typography className="popup-column-customform margin-opening-days" >OPENING DAYS</Typography>
                                                                    {weekdays.map((day) => (
                                                                        <FormControlLabel
                                                                            key={day}
                                                                            className="checkbox-dropdown"
                                                                            control={
                                                                                <Checkbox
                                                                                    className="checkbox-days"
                                                                                    checked={openingDays[day]}
                                                                                    onChange={this.handleCheckboxChange(day)}
                                                                                />
                                                                            }
                                                                            label={<span className="checkbox-dropdown-label">{day}</span >}
                                                                        />
                                                                    ))}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography className="popup-column-customform" >OPENS AT</Typography>
                                                                    {weekdays.map((day) => (
                                                                        <Box key={day} display="flex" alignItems="center">
                                                                            {openingDays[day] ? (
                                                                                <TextField
                                                                                    type="time"
                                                                                    sx={{
                                                                                        "& .MuiOutlinedInput-root": {                                                                            
                                                                                          "& fieldset": {
                                                                                            borderColor: "#712220", 
                                                                                          },
                                                                                          "&:hover fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "&.Mui-focused fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "& .MuiInputBase-input": {
                                                                                            padding: "0.833vw 1.111vw",
                                                                                            fontSize:"1.111vw",
                                                                                          },
                                                                                        },
                                                                                      }}
                                                                                    data-test-id={setTimes[day].opensAt}
                                                                                    placeholder="Select opening hours"
                                                                                    className="input-time-field"
                                                                                    value={setTimes[day].opensAt}
                                                                                    onChange={this.handleTimeChange(day, 'opensAt')}
                                                                                    fullWidth
                                                                                />
                                                                            ) : (
                                                                                <Typography className="popup-closed-typo">
                                                                                    CLOSED
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography className="popup-column-customform" >CLOSES AT</Typography>
                                                                    {weekdays.map((day) => (
                                                                        <Box key={day} display="flex" alignItems="center">
                                                                            {openingDays[day] ? (
                                                                                <TextField
                                                                                    type="time"
                                                                                    className="input-time-field"
                                                                                    value={setTimes[day].closesAt}
                                                                                    sx={{
                                                                                        "& .MuiOutlinedInput-root": {                                                                            
                                                                                          "& fieldset": {
                                                                                            borderColor: "#712220", 
                                                                                          },
                                                                                          "&:hover fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "&.Mui-focused fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "& .MuiInputBase-input": {
                                                                                            padding: "0.833vw 1.111vw",
                                                                                            fontSize:"1.111vw",
                                                                                          },
                                                                                        },
                                                                                      }}
                                                                                    onChange={this.handleTimeChange(day, 'closesAt')}
                                                                                    fullWidth
                                                                                />
                                                                            ) : (
                                                                                <Typography className="popup-closed-typo-2">
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                        <DialogActions className="action-popup">
                                                            <Button onClick={this.handleClosePopup} className="cancel-btn font">
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={this.handleSubmitPopup} className="submit-btn-popup font">
                                                                Submit
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Box>
                                            </Box>
                                            <Box className="category-5">
                                                <Box className="category-5-1">
                                                    <Typography style={webStyle.starTextStyle2}>
                                                        Happy Hours
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {weekdays.map((day) => (
                                                        <Box className="category-5-2" key={day}>
                                                            <FormControlLabel
                                                                value={day}
                                                                className="label-style"
                                                                control={<Radio icon={<span className="radioBox1" />} checkedIcon={<span className="radioBox1 checked" />} style={webStyle.radioBox1} />}
                                                                label={day}
                                                            />
                                                            <Box className="category-5-btn-container">
                                                                {displayedTimes2[day].closed ? (
                                                                    <>
                                                                        <Button className="category-5-btn font" data-test-id={displayedTimes2[day].opensAt} onClick={() => this.handleClickOpenWeekdayPopup2()}>
                                                                            Start
                                                                        </Button>
                                                                        <Button className={popupSubmit2?"category-5-btn-clicked font":"category-5-btn font"}>
                                                                            End
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    <Button className="category-5-btn-time">
                                                                        {`${displayedTimes2[day].opensAt} - ${displayedTimes2[day].closesAt}`}
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                    <Dialog className="popup-hours" open={this.state.openPopup2} onClose={this.handleClosePopup2}>
                                                        <DialogTitle className="popup-heading-customform"> {this.state.openCloseError && <div className="invalid-time-error">Please fill both opening and closing times for all selected days!</div>} {"Happy Hours"}</DialogTitle>                                                        <DialogContent className="popup-hours-content">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <Typography className="popup-column-customform margin-opening-days" >OPENING DAYS</Typography>
                                                                    {weekdays.map((day) => (
                                                                        <FormControlLabel
                                                                            key={day}
                                                                            className="checkbox-dropdown"
                                                                            control={
                                                                                <Checkbox
                                                                                    className="checkbox-days"
                                                                                    checked={openingDays2[day]}
                                                                                    onChange={this.handleCheckboxChange2(day)}
                                                                                />
                                                                            }
                                                                            label={<span className="checkbox-dropdown-label">{day}</span >}
                                                                        />
                                                                    ))}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography className="popup-column-customform" >OPENS AT</Typography>
                                                                    {weekdays.map((day) => (
                                                                        <Box key={day} display="flex" alignItems="center">
                                                                            {openingDays2[day] ? (
                                                                                <TextField
                                                                                    type="time"
                                                                                    sx={{
                                                                                        "& .MuiOutlinedInput-root": {                                                                            
                                                                                          "& fieldset": {
                                                                                            borderColor: "#712220", 
                                                                                          },
                                                                                          "&:hover fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "&.Mui-focused fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "& .MuiInputBase-input": {
                                                                                            padding: "0.833vw 1.111vw",
                                                                                            fontSize:"1.111vw",
                                                                                          },
                                                                                        },
                                                                                      }}
                                                                                    data-test-id={setTimes2[day].opensAt}
                                                                                    placeholder="Select opening hours"
                                                                                    className="input-time-field"
                                                                                    value={setTimes2[day].opensAt}
                                                                                    onChange={this.handleTimeChange2(day, 'opensAt')}
                                                                                    fullWidth
                                                                                />
                                                                            ) : (
                                                                                <Typography className="popup-closed-typo">
                                                                                    CLOSED
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography className="popup-column-customform" >CLOSES AT</Typography>
                                                                    {weekdays.map((day) => (
                                                                        <Box key={day} display="flex" alignItems="center">
                                                                            {openingDays2[day] ? (
                                                                                <TextField
                                                                                    type="time"
                                                                                    className="input-time-field"
                                                                                    value={setTimes2[day].closesAt}
                                                                                    sx={{
                                                                                        "& .MuiOutlinedInput-root": {                                                                            
                                                                                          "& fieldset": {
                                                                                            borderColor: "#712220", 
                                                                                          },
                                                                                          "&:hover fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "&.Mui-focused fieldset": {
                                                                                            borderColor: "#712220",
                                                                                          },
                                                                                          "& .MuiInputBase-input": {
                                                                                            padding: "0.833vw 1.111vw",
                                                                                            fontSize:"1.111vw",
                                                                                          },
                                                                                        },
                                                                                      }}
                                                                                    onChange={this.handleTimeChange2(day, 'closesAt')}
                                                                                    fullWidth
                                                                                />
                                                                            ) : (
                                                                                <Typography className="popup-closed-typo-2">
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={this.handleClosePopup2} className="cancel-btn">
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={this.handleSubmitPopup2} className="submit-btn-popup">
                                                                Submit
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Button disabled={this.isSubmitDisabled(this.state.briefDescription, this.state.selectedCategory)} data-test-id="submitBtn" className="submit-btn" onClick={() => this.helpUsSignUpForm()}>
                                                Submit
                                            </Button>
                                            <Box>
                                                <Backdrop
                                                    open={this.state.isLoading}
                                                    style={{ color: '#fff', zIndex: 1000 }}
                                                >
                                                    <CircularProgress color="inherit" size={30} />
                                                </Backdrop>
                                            </Box>
                                            <Snackbar open={this.state.successSnackbar2} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                <Alert
                                                    severity="success"
                                                    variant="filled"
                                                    sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                                                >
                                                    Account created successfully!
                                                </Alert>
                                            </Snackbar>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                    <Footer {...this.props} />
                </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        display: "flex",
        justifyContent: "center",
        color: "712220",
    },
    formContainer: {
        padding:"11.458vw 4vw",
        marginTop: "5.625vw",
        maxWidth:'1170px'
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        gap: "7.14px",
        height: "55px",
    },
    iconGrid: {
        marginBottom: "35.18px",
        justifyContent: "space-between",
    },
    uploadImgStyle: {
        marginBottom: "59px",
        gap: "15px",
        fontWeight: 250,
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    btnStyle: {
        backgroundColor: "70211F",
        width: "168px",
    },
    agree: {
        marginTop: "31px",
    },
    btn: {
        marginTop: "32px",
    },
    secondaryColor: {
        color: "#9C0E2E",
    },
    txtFieldStyle: {
        display: "flex",
        alignItems: "center",
        gap: "7px",
    },
    iconTextStyle: {
        fontSize: "7.13px",
        color: "712220",
        marginTop: "7.14px",
        fontStyle: "italic"
    },
    grid2Heading: {
        color: "#181818",
        fontWeight: 700,
        marginBottom: "2.222vw",
        alignItem: "center",
        justifyItem: "center",
        fontSize: "2.5vw",
    },
    textStyle: {
        fontStyle: "italic",
        
        fontWeight: 250,
    },
    starTextStyle: {
        lineHeight: "18px",
        fontSize: "1.111vw",
        color: "#712220",
        marginLeft: "0.486vw",
        fontWeight: 700,
    },
    starTextStyle2: {
        lineHeight: "18px",
        fontSize: "1.111vw",
        color: "#712220",
        fontWeight: 700,
    },
    radioBtnTextStyle: {
        lineHeight: "18px",
        fontSize: "1.111vw",
        marginRight: "27px",
        color: "#712220",
    },
    dropDownStyle: {
        width: "13.264vw",
        minWidth:"13.264vw",
        height: "3.125vw",
        color: "#712220",
        fontSize:"0.972vw",
    },
    dropDownStyle2: {
        width: "101px",
        height: "3.125vw",
        border: "1px solid #712220",
        marginTop: "10px",
        borderRadius: "5px",
    },
    radioBox1: {
        color: "#712220",
        padding:"0px 0.556vw 0px 0px",
        fontSize: "1.111vw",
    },
    radioBox: {
        color: "#712220",
        padding:"0px 7px 0px 0px",
        fontSize: "1.111vw",
    }
};
// Customizable Area End