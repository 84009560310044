// App.js - WEB
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Filteritems from "../../blocks/filteritems/src/Filteritems.web";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ForgotPassword from '../../blocks/email-account-login/src/ForgotPassword.web';
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPageBlock from "../../blocks/landingpage/src/LandingPageBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Checkin1 from "../../blocks/checkin1/src/Checkin1";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CustomisableUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import BusinessSignUpForm from "../../blocks/customform/src/BusinessSignUpForm.web";
import AboutUs from "../../blocks/navigationmenu/src/AboutUs.web";
import TravelBlogArticle from '../../blocks/navigationmenu/src/TravelBlogArticle.web';
import CustomisableBusinessProfile from '../../blocks/customisableuserprofiles/src/CustomisableBusinessProfile.web';
import ResetPassword from '../../blocks/email-account-login/src/ResetPassword.web';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions.web';
import SelectedArticle from '../../blocks/navigationmenu/src/SelectedArticle.web';
import HopperBadges from "../../blocks/customisableuserprofiles/src/HopperBadges.web";
import RecentActivity from "../../blocks/customisableuserprofiles/src/RecentActivity.web";

const routeMap = {
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SubscriptionPlans:{
  component:Customisableusersubscriptions,
 path:"/SubscriptionPlans/:id?"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Article:{
 component:SelectedArticle,
path:"/Article/:id"},
Uploadmedia3:{
  component:Uploadmedia3,
 path:"/Uploadmedia3"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue/:id"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Businesspage:{
 component:LandingPageBlock,
path:"/Businesspage"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Registration:{
 component:EmailAccountRegistration,
path:"/Registration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Login:{
 component:EmailAccountLoginBlock,
path:"/Login"},
ResetPassword:{
  component:ResetPassword,
 path:"/ResetPassword"},
BusinessSignupForm:{
  component:CustomForm,
 path:"/BusinessSignupForm"},
 BusinessSignupForm1:{
  component:BusinessSignUpForm,
 path:"/BusinessSignupForm1"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
"/": {
  component: () => <Redirect to="/TravelBlog" />,
  path: "/",
  exact: true,
},
TravelBlog: {
  component: TravelBlogArticle,
  path: "/TravelBlog/:id?", 
  exact: true,
},
Explore:{
 component:AdvancedSearch,
path:"/Explore/:id?"},
Checkin1:{
 component:Checkin1,
path:"/Checkin1"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
CustomisableUserProfile:{
  component:CustomisableUserProfile,
  path:"/CustomisableUserProfile"
},
CustomisableBusinessProfile:{
  component:CustomisableBusinessProfile,
  path:"/CustomisableBusinessProfile"
},
HopperBadges:{
component:HopperBadges,
  path:"/HopperBadges/:patronId?"
},
RecentActivity:{
  component:RecentActivity,
  path:"/RecentActivity/:patronId?"
},
AboutUs:{
  component:AboutUs,
 path:"/AboutUs"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/Home',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;