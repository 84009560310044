import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface AllBadgesType {
  "Amenity Badge": SingleBadgeType
  "Category Badge": SingleBadgeType
  "General Badge": SingleBadgeType
}
export interface SingleBadgeType {
  total_count: number | null,
  badges: BadgesType[]
}
interface BadgesType {
  badge_id: number | null,
  amenity_badge_categories: string,
  badge_name: string,
  description: string,
  earned: boolean,
  badge_category_name: string,
  icon: undefined | string,
  date:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeBadgeTab: string
  patronId: string
  hopperBadges: AllBadgesType
  isShowHopperDetail: boolean
  singleBadgeData:BadgesType
  isLoading:boolean;
  badgeName:string;
  shareUrl:string;
  setOpenShareState:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HopperBadgesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  hopperBadgesApiCallId: string = "";
  getShareUrlApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeBadgeTab: "all",
      isLoading:false,
      patronId: '',
      badgeName:"",
      shareUrl:"",
      setOpenShareState:false,
      hopperBadges: { "Amenity Badge": { total_count: null, badges: [] }, "Category Badge": { total_count: null, badges: [] }, "General Badge": { total_count: null, badges: [] } },
      isShowHopperDetail:false,
      singleBadgeData:{amenity_badge_categories:'',badge_category_name:"",badge_id:null,badge_name:'',description:"",earned:false,icon:undefined,date:""}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.getHopperData(message)
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getShareUrlApiCallId != null &&
      this.getShareUrlApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ shareUrl: responseJson.link });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const patronId = this.props.navigation.getParam('patronId')
    this.setState({ patronId })
  }
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.patronId !== this.state.patronId || prevState.activeBadgeTab !== this.state.activeBadgeTab) {
      this.getHopperBadges()
    }
  }
  getHopperBadges = () => {
    this.setState({isLoading:true})
    let Token = window.localStorage.getItem("token")

    const PCheaders = {
      "Content-Type": configJSON.searchApiContentType,
      token: Token
    };
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.hopperBadgesApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHopperBadgesEndPoint}?account_id=${this.state.patronId}&type=${this.state.activeBadgeTab}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getHopperData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.hopperBadgesApiCallId) {
        this.setState({isLoading:false})
        if (responseJson) {
          this.setState({
            hopperBadges: responseJson
          });
        } else {
          this.setState({
            hopperBadges: { "Amenity Badge": { total_count: null, badges: [] }, "Category Badge": { total_count: null, badges: [] }, "General Badge": { total_count: null, badges: [] } }
          });
        }

      }
    }
  }
  handleBackClick = (sliderRef: any) => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  handleNextClick = (sliderRef: any) => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  handleTabChange = (tabName: string) =>{
    this.setState({ activeBadgeTab: tabName},()=>{
      this.getHopperBadges()
    })
  }
  toggleBagdeDetailShow = () =>{
    this.setState(prevState=>({isShowHopperDetail: !prevState.isShowHopperDetail}))
  }
  handleShowBadgeToggle = (badgeData: BadgesType) =>{
    this.toggleBagdeDetailShow()
    this.setState({singleBadgeData: badgeData})
  }
  goBack = () =>{
    this.props.navigation.goBack()
  }

  viewAllBadges = (badgeName:string) =>{
    this.setState({badgeName:badgeName})
  }
  backViewall = () =>{
    this.setState({badgeName:""})
  }

  handleCloseBadgepopup = () => {
    this.setState({setOpenShareState:false})
  };

  handleClickOpenShareBadgePopup = (id:number) => {
    this.setState({setOpenShareState:true})
    this.getShareBadgesUrlFunc(id);
  }

  getShareBadgesUrlFunc = (id:number) =>{

    const header = {
      "Content-Type": configJSON.searchApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShareUrlApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShareUrlEndPoint}?id=${id}&type=Badge`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
