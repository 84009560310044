import React from "react";
// Customizable Area Start
import {
    Grid,
    Paper,
    Box,
    Typography,
} from "@material-ui/core";
import "@mui/styles";
import "./CustomisableUserProfiles.css";
// Customizable Area End

import CustomisableUserProfilesController, {
    Props,
  } from "./CustomisableUserProfilesController";
import PhotoLibrary from "../../photolibrary/src/PhotoLibrary";  

export default class Gallery extends CustomisableUserProfilesController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const imagesToShow = this.props?.image_library?.slice(0, 6);
        const placeholders = Array.from({ length: 6 - imagesToShow?.length });
        return (
            <Grid item xs={6} className="itemLibraryGridContainer" >
            <Paper className={this.props.editModeGallery ? "itemLibraryPaper disabled" : "itemLibraryPaper"}>
              <PhotoLibrary {...this.props} image_library={this.props.image_library} />
              <Box className="itemGridBox" sx={{ flexGrow: 1 }}>
                {imagesToShow && imagesToShow.map((image, index) => (
                  <Grid item xs={4} key={index}>
                    <Box style={{display:"flex", justifyContent:"center"}}>
                    <Paper key={index} className="libraryImageContainer">
                      <img style={webStyle.imgGridLibrary} src={image.url} alt={image.filename} />
                    </Paper>
                    </Box>
                  </Grid>
                ))}
                {placeholders.map((_, index) => (
                  <Grid item xs={4} key={index}>
                    <Box style={{display:"flex", justifyContent:"center"}}>
                    <Paper key={index} className="libraryImageContainer"></Paper>
                    </Box>
                  </Grid>
                ))}
              </Box>
            </Paper>
          </Grid>
        );
    // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    imgGridLibrary: {
        width:"100%",
        height:"8.11vw",
        borderRadius:"8px",
    },
    itemHeading:{
        color:"#712220",
        fontWeight:700,
        fontSize:"1.112vw",
        fontFamily: 'Museo Sans", sans-serif',
    },
    itemHeading2:{
      color:"#712220",
      fontWeight:600,
      fontSize:"0.972vw",
      fontFamily: 'Museo Sans", sans-serif',
  },
}
        