import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Box, TextField ,RadioGroup, Radio, FormControlLabel, Dialog, DialogActions} from "@mui/material";
import { Typography,Button } from "@material-ui/core";
import { addIcon, placeholder, startImg, UploadImgIcon, imgShare, imgCal, likeStar, btnCancel } from "./assets";
import dayjs from "dayjs";
import Share from "../../share/src/Share.web";

const theme = createTheme({
    typography: {
        "fontFamily": `"Museo Sans", sans-serif`,
    }
});
// Customizable Area End

import SchedulingController, { Props } from "./SchedulingController";

export default class Scheduling extends SchedulingController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const customDayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
        const eventType = ['Food','Tasting','Trivia','Comedy','Drink','Tournament','Live Music'];
        const { selectedEventDetails, selectedDateCal, addEventsEnabled} = this.state;

        const bannerUrl = selectedEventDetails && 
                  selectedEventDetails.calendar_banner && 
                  selectedEventDetails.calendar_banner.length > 0
                    ? selectedEventDetails.calendar_banner[0]?.url
                    : placeholder;

        const renderDay = (day: any, pickersDayProps: any) => {
            const event = this.getEventForDate(day);
            const isSelected = pickersDayProps.selected;
            const today = dayjs().startOf('day');
            const isBeforeToday = day.isBefore(today, 'day')

            return (
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                    <PickersDay
                        {...pickersDayProps}
                        day={day}
                        sx={{
                            ...webStyle.dayCell,
                            '&.MuiPickersDay-root.Mui-selected': {
                                backgroundColor: '#C33836'
                            }
                        }}
                    >
                        <Typography
                            style={{ fontWeight: 600, fontSize: '1.111vw' }}
                        >
                            {day.date()}
                        </Typography>
                        {
                            addEventsEnabled ? (
                                event ? (
                                    <Typography
                                        style={{
                                            fontSize: '0.8888vw',
                                            fontWeight: 600,
                                            marginTop: '0.2224vw',
                                            textAlign: 'center',
                                            padding: '0px 0.2224vw',
                                            color: isSelected ? '#FFFFFF' : '#712220',
                                        }}
                                    >
                                        {event.attributes.event_type}
                                    </Typography>
                                ) : (
                                    !isBeforeToday && <img style={webStyle.imgStyle} src={addIcon} />
                                )
                            ) : (
                                event && (
                                    <Typography
                                        style={{
                                            fontSize: '0.8888vw',
                                            fontWeight: 600,
                                            marginTop: '0.2224vw',
                                            textAlign: 'center',
                                            padding: '0px 0.2224vw',
                                            color: isSelected ? '#FFFFFF' : '#712220',
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word', 
                                            overflowWrap: 'break-word',
                                        }}
                                    >
                                        {event.attributes.event_type}
                                    </Typography>
                                )
                            )
                        }


                    </PickersDay>
                </Box>
            );
        };
    
        return (
            <ThemeProvider theme={theme}>
                {
                    this.props.canAddEvents &&
                    <Box style={{marginBottom:'-3.4vw'}}>
                        <Button className="upload-popup-btns-event" onClick={this.editAddEvents}>
                            Edit/Add Event
                        </Button>
                    </Box>
                }
                <Typography style={{textAlign:'center', fontWeight:600, fontSize:'2.2224vw', marginBottom:'2.7776vw'}}>
                    EVENT/SPECIALS CALENDAR
                </Typography>
                <Box style={webStyle.container}>
                    <Box sx={{ width: '45.7936vw' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={webStyle.weekdayContainer}>
                                {customDayNames.map((day, index) => (
                                   <Typography key={index} style={{ width: '4.5776vw', textAlign: 'center', fontWeight: 400, fontSize:'1.111vw', color:"#18181880" }}>
                                        {day}
                                    </Typography>
                                ))}
                            </Box>
                            <DateCalendar
                                disablePast={addEventsEnabled}
                                sx={webStyle.calendar}
                                disableHighlightToday
                                className="custom-calendar"
                                slots={{
                                    day: (dayProps:any) => renderDay(dayProps.day, dayProps),
                                }}
                                onChange={this.handleDateChange} 
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box>
                        <Typography style={webStyle.detailHeading}>Event Details</Typography>
                        {addEventsEnabled ? (
                            <Box>
                                <div className="container" style={{marginBottom:'0.556vw'}}>
                                <img src={ this.state.imageUrl ? this.state.imageUrl :  placeholder } alt="Image" style={webStyle.bannerImg}/>                                    <label htmlFor="uploadPictureEvent" style={{ cursor: 'pointer' }}>
                                        <img style={{ width: "1.389vw" }} className="buttonStyleEvent" src={UploadImgIcon} alt="Upload Icon" />
                                    </label>
                                    <input
                                        type="file"
                                        id="uploadPictureEvent"
                                        style={{ display: 'none' }}
                                        data-test-id="uploadImageProfile"
                                        onChange={this.handleImageUpload}
                                    />
                                </div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    value={this.state.event_title}
                                    placeholder="Add Event Title"
                                    className="formInputStyleEvents"
                                    data-test-id={"txtInputFirst"}
                                    fullWidth
                                    name="event_title"
                                    onChange={(e) =>
                                        this.handleInputChange(e.target.name, e.target.value)}
                                />
                                <Typography style={webStyle.event_type_heading}>Event Type</Typography>
                                <Box className="category-1-event" sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '0.5rem',
                                    marginBottom:'0.556vw',
                                    "& .MuiFormControlLabel-root": { width: "calc(50% - 0.5rem)" },
                                    "& .MuiTextField-root": { width: "calc(50% - 0.5rem)" }
                                }}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="f"
                                        name="event_type"
                                        className="category-1-radio-event"
                                        onChange={(e) => {
                                            this.handleInputChange(e.target.name, e.target.value);
                                            this.setState({ other_type: "" });
                                        }}
                                        
                                    >
                                        {eventType.map((type, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={type}
                                                    className="label-style-event"
                                                    control={
                                                        <Radio
                                                            icon={<span className="radioBox1-event" />}
                                                            checked={this.state.event_type === type}
                                                            checkedIcon={<span className="radioBox1-event checked" />}
                                                            style={webStyle.radioBox1}
                                                        />
                                                    }
                                                    label={type}
                                                    style={webStyle.radioBtnTextStyle}
                                                />
                                        ))}
                                        <TextField
                                            className="other-textfield"
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={this.state.other_type}
                                            placeholder="Other"
                                            data-test-id="otherCategoryLabel"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    height: "2.917vw",
                                                    fontSize:'1.11vw',
                                                    width:'11.2vw',
                                                    "& fieldset": {
                                                        borderColor: "#712220",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#712220",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#712220",
                                                    },
                                                    "& .MuiOutlinedInput-input": {
                                                        fontSize: "1.111vw",
                                                    },
                                                },
                                            }}
                                            name="other_type"
                                            onChange={(e) => {
                                                this.handleInputChange(e.target.name, e.target.value);
                                                this.setState({ event_type: e.target.value });
                                            }}                                            
                                        />
                                    </RadioGroup>
                                </Box>
                                <TextField
                                    variant="outlined"
                                    placeholder="Add Date"
                                    fullWidth
                                    value={selectedDateCal}
                                    margin="normal"
                                    size="small"
                                    style={{cursor:'none'}}
                                    className="formInputStyleEvents"
                                    data-test-id={"txtInputSecond"}
                                    name="booking_date"
                                    onChange={(e) =>
                                        this.handleInputChange(e.target.name, e.target.value)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    value={this.state.start_time}
                                    placeholder="Add Start Time"
                                    className="formInputStyleEvents"
                                    data-test-id={"txtInputThird"}
                                    fullWidth
                                    type="time"
                                    name="start_time"
                                    onChange={(e) =>
                                        this.handleInputChange(e.target.name, e.target.value)}
                                />
                                 <TextField
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    value={this.state.end_time}
                                    placeholder="Add End Time"
                                    className="formInputStyleEvents"
                                    data-test-id={"end_time"}
                                    fullWidth
                                    type="time"
                                    name="end_time"
                                    onChange={(e) =>
                                        this.handleInputChange(e.target.name, e.target.value)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    value={this.state.event_link}
                                    placeholder="Add Link"
                                    className="formInputStyleEvents"
                                    data-test-id={"txtInputFourth"}
                                    fullWidth
                                    name="event_link"
                                    onChange={(e) =>
                                        this.handleInputChange(e.target.name, e.target.value)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    value={this.state.description}
                                    placeholder="Add Details/Description"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            minHeight: "9.444vw",
                                            alignItems: "flex-start",
                                            lineHeight: '2vw',
                                            "& fieldset": {
                                                borderColor: "#712220",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#712220",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#712220",
                                            },
                                            "& .MuiOutlinedInput-input": {
                                                fontSize: "1.111vw",
                                            },
                                        },
                                    }}
                                    data-test-id={"txtInputFifth"}
                                    fullWidth
                                    multiline
                                    name="description"
                                    onChange={(e) =>
                                        this.handleInputChange(e.target.name, e.target.value)}
                                />
                                <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.111vw' }}>
                                    <Button onClick={()=>this.addEditEventSubmit(selectedEventDetails?.id)} className="upload-popup-btns-event" disabled={!this.state.start_time || !this.state.end_time}>
                                        Submit
                                    </Button>
                                    <Button className="upload-popup-btns-event" onClick={()=> this.deleteEvent(selectedEventDetails?.id)}>Delete</Button>
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                <img
                                    style={webStyle.bannerImg}
                                    src={ bannerUrl ? bannerUrl: placeholder }
                                    alt="Event Picture"
                                />
                                <Box style={{ display: 'flex', gap: '0.903vw', margin: '1.25vw 0px 0.417vw 0px' }}>
                                    <Typography style={webStyle.detailTitle}> {selectedEventDetails?.event_title || "Event Title"} </Typography>
                                        <img style={webStyle.starImg} src={
                                            this.state.likedEvents[selectedEventDetails?.id]
                                                ? likeStar
                                                : startImg
                                        } onClick={() => {selectedEventDetails?.id && this.props.id && this.isLikeBusinessEvent(selectedEventDetails?.id)}}></img>
                                </Box>
                                <Typography style={webStyle.detailType}>{selectedEventDetails?.event_type || "EVENT TYPE"}</Typography>
                                <Typography style={webStyle.detailDate}>
                                    {selectedEventDetails?.date_of_booking && selectedEventDetails?.start_time
                                        ? `${selectedEventDetails.date_of_booking   } | ${selectedEventDetails.start_time}`
                                        : "Date and Time"}
                                </Typography>
                                <span
                                    style={{ ...webStyle.detailLink, cursor: "pointer" }}
                                    onClick={() => {
                                        const eventLink = selectedEventDetails?.event_link || "#";
                                        const validLink = eventLink.startsWith("http://") || eventLink.startsWith("https://")
                                            ? eventLink
                                            : `https://${eventLink}`;
                                        window.open(validLink, "_blank");
                                    }}                        >
                                    {selectedEventDetails?.event_link || "Link"}
                                </span>
                                <Typography style={webStyle.detailDesc}>Details/Description:{selectedEventDetails?.description} </Typography>
                            </Box>
                        )
                        }
                        {
                            !this.props.canAddEvents && selectedEventDetails &&
                            <Box style={{display:'flex', gap:'1.944vw'}}>
                                <Button className="btn-subdirectory-cal" data-test-id="handleClickBtn1" onClick={this.handleClickOpenShareEventPopup}>
                                    <img className="share-btn-icon-catalogue-event" src={imgShare} />
                                    <Typography className="btn-share-moreinfo-container-event" >
                                        Share
                                    </Typography>
                                    </Button>
                                    <Dialog className="pop-up-dialog-catalogue" open={this.state.setOpenShareEvent} onClose={this.handleClickCloseShareEventPopup}>
                                        <DialogActions className="pop-up-dialog-action-catalogue">
                                            <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn2" onClick={this.handleClickCloseShareEventPopup} color="primary">
                                                <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                                            </Button>
                                        </DialogActions>
                                        <Share {...this.props} shareUrl={this.state.shareUrl} />
                                    </Dialog>
                                    <Button className="btn-subdirectory-cal" data-test-id="handleClickBtn2" onClick={this.addToCalendar}>
                                        <img className="cal-btn-icon-catalogue" src={imgCal} />
                                        <Typography className="btn-share-moreinfo-container-event" >
                                            Add to Calendar                                
                                        </Typography>
                                    </Button>
                            </Box>
                        }
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}

// Customizable Area Start
const webStyle = {
    starImg:{
        width:'1.875vw'
    },
    event_type_heading:{
        fontSize:'0.972vw',
        fontWeight:600,
        marginTop:'0.556vw'
    },
    radioBox: {
        color: "#712220",
        padding:"0px 0.486vw 0px 0px",
        fontSize: "1.111vw",
    },
    radioBox1: {
        color: "#712220",
        padding:"0px 0.556vw 0px 0px",
        fontSize: "1.111vw",
    },
    radioBtnTextStyle: {
        lineHeight: "1.25vw",
        fontSize: "1.111vw",
        marginRight: "1.875vw",
        color: "#712220",
    },
    imgStyle:{
        width:"2.5vw",
    },
    bannerImg:{
        width:'13.2224vw',
        height:'13.2224vw',
        borderRadius:'8px'
    },
    detailHeading:{
        fontSize:'1.6664vw',
        fontWeight:600,
        marginBottom:'1.25vw'
    },
    detailTitle:{
        fontSize:'1.6664vw',
        fontWeight:600,
    },
    detailType:{
        fontSize:'0.972vw',
        fontWeight:600,
        color:'#C33836',
        letterSpacing:'2px'
    },
    detailDate:{
        fontSize:'1.667vw',
        fontWeight:400,
        margin:'1.25vw 0px 0.278vw 0px'
    },
    detailLink:{
        fontSize:'1.389vw',
        fontWeight:400,
        fontStyle:'italic'
    },
    detailDesc:{
        fontSize:'1.25vw',
        fontWeight:400,
        margin:'1.458vw 0px 3.056vw 0px'
    },
    container: {
        display: 'flex',
        gap: '3.125vw'
    },
    weekdayContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    weekday: {
        width: '4.5776vw',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: '1.111vh',
        color: "#18181880"
    },
    calendar: {
        width: '45.7936vw',
        height: '39.3336vw',
        maxHeight: '39.3336vw',
        '& .MuiDayCalendar-weekDayLabel': {
            display: 'none',
        },
        "& div[role=row]": {
            justifyContent: "space-between !important",
            marginBottom: '1.111vw',
        },
        "& div[role=column]": {
            justifyContent: "space-between !important",
            marginBottom: '1.111vw',
        },
        '& .MuiPickersCalendarHeader-root': {
            paddingLeft: '18.083vw',
            paddingRight: 0,
            marginTop: '2.361vw',
            marginBottom:'0px'
        }
    },
    dayCell: {
        minWidth: '4.5776vw',
        width: '4.5776vw',
        height: '5.7224vw',
        paddingTop: '0.556vw',
        border: '1px solid #712220',
        borderRadius: '8px',
        '&:hover': {
            borderColor: '#712220',
        },
        '&:select': {
            backgroundColor: '#712220',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
};
// Customizable Area End
